import { Close, KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getData } from "../../Library/Api/Services";

const initProps = {
  name: "",
  label: "No Label Provided",
  value: "",
  menuItems: [],
  multiple: false,
  onChange: () => {},
  fontVariant: "form_text2",
  featureId: "",
  dataMatch: {},
  width: 200,
  mapper: { label: "", value: "" },
  returnData: ["Header.*", "Fields.*"],
  helperText: "",
  renderTags: () => {},
};

function AutoComplete(props = initProps) {
  let {
    name,
    value,
    onChange,
    multiple,
    menuItems,
    fontVariant,
    width,
    mapper,
    featureId,
    dataMatch,
    returnData,
    helperText,
    renderTags,
  } = props;

  const { typography } = useTheme();

  const [options, setOptions] = useState(menuItems || null);
  const [, setLoading] = useState(false);
  const [select, setSelect] = useState("");

  useEffect(() => {
    setOptions(menuItems);
  }, [menuItems]);

  if (!fontVariant) fontVariant = "form_text2";
  if (!onChange) onChange = () => {};
  if (!returnData) {
    if (mapper && mapper.label && mapper.value) {
      returnData = [`Header.${mapper.value}`, `Fields.${mapper.label} `];
    } else {
      returnData = ["Header.*", "Fields.*"];
    }
  }

  useEffect(() => {
    if (!value) {
      setSelect("");
    } else {
      if (options && Array.isArray(options) && options.length > 0) {
        if (!multiple) {
          const option = options.find((o) => o.value === value);
          setSelect(option.label);
        } else {
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (featureId) {
      setLoading(true);
      getData({ featureId, documentType: "ETY", returnData, dataMatch })
        .then((res) => {
          if (res?.ReturnData) {
            if (mapper) {
              setOptions(
                res.ReturnData.map((r) => ({
                  label: r[mapper.label],
                  value: r[mapper.value],
                }))
              );
            } else {
              setOptions(
                res.ReturnData.map((r) => ({
                  label: r.EntityName || "No Label available",
                  value: r.EntityID,
                }))
              );
            }
          } else {
            setOptions([
              {
                label: "No Data",
                value: "",
              },
            ]);
          }
        })
        .catch((e) => {
          setOptions([{ label: "Error Occured", value: "" }]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMatch]);

  return (
    <Box>
      <Autocomplete
        size="small"
        sx={{
          width: width || 200,
          "& .MuiAutocomplete-option": {
            backgroundColor: "red",
          },
          "& .MuiInput-root": {
            paddingBottom: 0,
          },
          "& .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input": {
            padding: "4px 0 5px",
          },
        }}
        popupIcon={<KeyboardArrowDown fontSize="small" />}
        clearIcon={<Close sx={{ fontSize: "1rem" }} />}
        renderOption={(params, option) => (
          <MenuItem
            {...params}
            sx={{ ...params.sx, ...typography[fontVariant] }}
          >
            {option.label}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            size="small"
            InputProps={{
              ...params.InputProps,
              sx: {
                ...typography[fontVariant],
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: false,
              sx: {
                ...typography[fontVariant],
                top: "-12px",
                "& +.MuiInputBase-root": {
                  marginTop: 0,
                },
              },
            }}
            FormHelperTextProps={{ sx: { marginTop: "3px" } }}
            placeholder={helperText}
            helperText={
              multiple
                ? value.length > 0
                  ? helperText
                  : " "
                : value
                ? helperText
                : " "
            }
          />
        )}
        renderTags={renderTags || undefined}
        inputValue={select}
        options={options || []}
        onChange={(e, option, action) => {
          if (multiple) {
            // For multiple selections
            if (action === "selectOption") {
              onChange([...option.map((o) => o.value)], name);
            } else if (action === "removeOption") {
              onChange([...option.map((o) => o.value)], name);
            } else if (action === "clear") {
              onChange([], name);
            }
          } else {
            // For single selection
            if (action === "selectOption") {
              onChange(option.value, name);
            } else {
              onChange("", name);
            }
          }
        }}
        multiple={multiple}
      />
    </Box>
  );
}

export default AutoComplete;
