import React, { useEffect, useState } from "react";
import { RadioGroupMenuItem, RadioGroupPropTypes } from "./types";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Typography,
} from "@mui/material";
import Radio from "../../Radio/Radio_1";
import ErrorTooltip from "../../ErrorTooltip";

const RadioGroup_1: React.FC<RadioGroupPropTypes> = (
  props: RadioGroupPropTypes
) => {
  const {
    name,
    value,
    menuItems = [],
    onChange = () => {},
    error,
    variant = "standard",
    labelFontVariant = "form_h1",
    optionFontVariant = "form_text2",
    gap,
    row,
    radioProps = {},
    attributes,
  } = props;

  let { label, required = false, helperText = "", visibility = true } = props;

  /**
   * This condition assigns all necessary attributes to the input
   */
  if (attributes) {
    const { Input, Visibility } = attributes;
    required = Input === "M";
    visibility = Visibility === "D";
    // label = Label || "";
  }

  const [radioValue, setRadioValue] = useState<string | number>(value || "");

  useEffect(() => {
    setRadioValue(value);
  }, [value]);

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    onChange(value, event.target.name);
    setRadioValue(value);
  };

  return !visibility ? (
    <></>
  ) : (
    <ErrorTooltip title={error || ""}>
      <FormControl
        component={"fieldset"}
        error={error ? true : false}
        variant={variant}
        required={required}
      >
        {label && (
          <FormLabel id="radio-group" sx={{ color: "black" }}>
            <Typography variant={labelFontVariant}>{label}</Typography>
          </FormLabel>
        )}
        <RadioGroup
          aria-labelledby="radio-group"
          name={name}
          value={radioValue}
          onChange={onChangeHandler}
          sx={{ gap: gap }}
          row={row}
        >
          {menuItems.map((item: RadioGroupMenuItem, index: number) => {
            const Label = item.label;
            const label = item.label || item;
            const value = item.value || item.label || item;
            const disabled = item.disabled || false;

            return (
              <FormControlLabel
                key={index}
                disabled={disabled}
                value={value}
                control={<Radio {...radioProps} />}
                label={
                  typeof label === "string" ? (
                    <Typography variant={optionFontVariant}>{label}</Typography>
                  ) : (
                    <Label />
                  )
                }
              />
            );
          })}
        </RadioGroup>
        {helperText && (
          <FormHelperText>
            <Typography variant="helper_text">{helperText}</Typography>
          </FormHelperText>
        )}
      </FormControl>
    </ErrorTooltip>
  );
};

export default RadioGroup_1;
