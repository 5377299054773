import { SmallInput } from "../Dropdown/SmallDropdown";

const DateInput = (props) => {
  const { name, value, onChange, month, year, width }=props
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const getDayUpperLimit = (month, year) => {
    if (month === 2) {
      return isLeapYear(year) ? 29 : 28;
    } else if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
      return 31;
    } else {
      return 30;
    }
  };

  return (
    <SmallInput
      name={name}
      type="number"
      onChange={(e) => {
        const val = e.target.value;
        if (!val) {
          onChange(null, e.target.name);
        } else {
          if (val >= 0 && val <= 9) {
            onChange(`0${val}`, e.target.name);
          } else if (val <= getDayUpperLimit(month, year)) {
            onChange(`${val}`, e.target.name);
          }
        }
      }}
      value={value ? +value : null}
      sx={{ width: width || "42px" }}
      placeholder="DD"
    />
  );
};

export default DateInput;
