import React, { useEffect, useState } from "react";
import { serviceFunctionLibrary } from "../../../Library/Helpers";
import {
  DropdownMenuItem,
  DropdownPropTypes,
  FunctionDropdownPropTypes,
} from "./types";
import { ServiceFunctionLibraryType } from "../../../Library/Api/types";

const withFunction = (Dropdown: React.FC<DropdownPropTypes>) => {
  return (props: FunctionDropdownPropTypes & ServiceFunctionLibraryType) => {
    const {
      functionName,
      params,
      filter,
      mapper,
      onChange,
      OptionComponent,
      ValueComponent,
      groupBy,
      ...dropdownProps
    } = props;

    const [data, setData] = useState<Array<any>>([]);
    const [items, setItems] = useState<
      | string[]
      | DropdownMenuItem[]
      | { [key: string]: string[] | DropdownMenuItem[] }
    >([]);

    function filterData(item: any) {
      if (filter) return filter(item);
      return true;
    }

    const serviceFunctionLibraryProps = {
      functionName: functionName,
      params: params,
    } as ServiceFunctionLibraryType;

    useEffect(() => {
      if (functionName) {
        serviceFunctionLibrary(serviceFunctionLibraryProps)
          .then((res: any) => {
            const data = res.ReturnData;
            if (data && Array.isArray(data) && data.length > 0) {
              setData(data);
              if (groupBy) {
                const groupData = groupBy(data);
                setItems(
                  Object.entries(groupData).reduce(
                    (
                      total: {
                        [key: string]: string[] | DropdownMenuItem[];
                      },
                      [key, value]
                    ) => {
                      total[key] = value
                        .map((d: any) => ({
                          label:
                            typeof mapper.label === "function"
                              ? mapper.label(d)
                              : d[mapper.label],
                          value: d[mapper.value],
                        }))
                        .filter(filterData);
                      return total;
                    },
                    {}
                  )
                );
              } else {
                setItems(
                  data
                    .map((d: any) => ({
                      label:
                        typeof mapper.label === "function"
                          ? mapper.label(d)
                          : d[mapper.label],
                      value: d[mapper.value],
                    }))
                    .filter(filterData)
                    .sort((a, b) => a.label.localeCompare(b.label))
                );
              }
            }
          })
          .catch(() => {})
          .finally(() => {});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionName, params]);

    return (
      <Dropdown
        {...dropdownProps}
        onChange={(value, type, label) => {
          const document = data.find((i) => i[mapper.value] === value);
          onChange(value, type, label, document);
        }}
        menuItems={items}
        OptionComponent={
          OptionComponent
            ? ({ menuItem }) => {
                const { value } = menuItem;
                const document = data.find((d) => d[mapper.value] === value);
                return <OptionComponent document={document} />;
              }
            : undefined
        }
        ValueComponent={
          ValueComponent
            ? ({ value }) => {
                const document = data.find((d) => d[mapper.value] === value);
                return <ValueComponent document={document} />;
              }
            : undefined
        }
      />
    );
  };
};

export default withFunction;
