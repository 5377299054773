import React, { useEffect, useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

import Molecule from "../../Molecule";
import SubdomainInput from "./SubdomainInput";
import { Constants, getAuthDocument, getRole } from "./utils";
import useStateUpdater from "../../Library/Hooks/useStateUpdater";
import { isEmpty } from "../../Library/Helpers";
import LoginForm from "./LoginForm";
import ForgetPassword from "./ForgetPassword";
import ChangePassword from "./ChangePassword";
import SetPassword from "./SetPassword";
import AlertMessage from "../../Card/Messages/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../Redux/Actions/Actiontypes";
import TypeEffect from "./TypeEffect";
import { EmployWiseIcon } from "../../Library/Assets/Icons";

const { EwModal } = Molecule;

function AuthenticationWrapper({ children }) {
  const { palette } = useTheme();
  const [loading, setLoading] = useState(true);
  const [viewState, setViewState] = useState(
    Constants.LOGIN_VIEW_STATE.CUSTOMER_CODE_VIEW
  );
  const [state, updateState] = useStateUpdater({
    userId: "",
    subDomain: "",
    inputLabel: Constants.LABEL_MAPPER.default,
    successMessage: null,
  });

  // const token = Cookies.get(Constants.ACCESS_TOKEN);
  // const tamToken = Cookies.get(Constants.TAM_ACCESS_TOKEN);
  const token = useSelector((state) => state.AuthReducer.Token);
  const dispatch = useDispatch();
  const pageUrl = window.location.href.replace("#/", "");
  const host = window.location.host;

  let containerOptions = {
    width: 1150,
    height: 600,
    minHeight: 500,
    direction: "row",
  };
  // if (isTablet) containerOptions.width = 850;
  // if (isMobile) {
  //   containerOptions.width = 400;
  //   containerOptions.direction = "column";
  //   containerOptions.height = 0.7 * height;
  // }

  const dispatchToken = ({ token, refreshToken, isTam, subDomain }) => {
    if (isTam) {
      const payload = {
        Token: token,
        tenantCode: "TAM_EW",
        userId: "",
        subDomain: "tam",
        isTam: true,
      };
      const decodedIdToken = jwt_decode(token);

      if (decodedIdToken["custom:UserRole"] === "TA") {
        dispatch({
          type: Actions.ADD_AUTH_DATA,
          payload,
        });
        Cookies.set(Constants.TAM_ACCESS_TOKEN, token);
      } else {
        updateState("Unauthorized", "errorMessage");
      }
      Cookies.remove(Constants.SUBDOMAIN_COOKIE);
      return;
    }
    const decodedToken = jwt_decode(token);
    const tenantCode = decodedToken.iss.split("/").pop();

    const payload = {
      Token: token,
      refreshToken,
      tenantCode,
      userId: decodedToken.UserID,
      subDomain,
      role: getRole(decodedToken.realm_access?.roles),
      userData: decodedToken,
    };
    dispatch({
      type: Actions.ADD_AUTH_DATA,
      payload,
    });

    Cookies.set(Constants.ACCESS_TOKEN, token, {
      //    expires: new Date(decodedToken.exp * 1000),  // a service is needed to take refresh token and give new access token
    });
    Cookies.set(Constants.REFRESH_TOKEN, refreshToken, {
      //   expires: new Date(decodedRefreshToken.exp * 1000),
    });
    Cookies.set(Constants.SUBDOMAIN_COOKIE, subDomain);
  };
  const onGetAuthenticationDocument = async (subDomain) => {
    let authDocument;
    try {
      authDocument = await getAuthDocument({ subDomain });
    } catch (e) {
      authDocument = {
        Error: e.message || e,
      };
    }

    console.log("Auth doc is: ", authDocument);
    const labelMapper = Constants.LABEL_MAPPER;
    const loginTypes = Constants.LOGIN_TYPES;
    if (authDocument.Error) {
      return {
        error: true,
        message: authDocument.Error,
      };
    }
    if (authDocument.LoginMethod === loginTypes.SSO) {
      window.location.href = authDocument.UIRedirectURL;
      return {
        success: true,
        isSSO: true,
      };
    } else {
      const inputLabel =
        labelMapper[authDocument.LoginName] || labelMapper.default;
      updateState(authDocument, "authDocument");
      updateState(inputLabel, "inputLabel");
      setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW);
    }
    return {
      success: true,
    };
  };
  const checkUrlParams = () => {
    if (pageUrl.includes("?")) {
      const params = pageUrl.split("?")[1]?.split("&");
      const urlConstants = Constants.URL_PARAMS;
      const stateConstants = Constants.LOGIN_VIEW_STATE;
      params.forEach((ele) => {
        let [key, value] = ele.split("=");
        if (key === urlConstants.SET_PASSWORD) {
          setViewState(stateConstants.SET_PASSWORD_VIEW);
          updateState(value, "userId");
        } else if (key === urlConstants.TAM) {
          setViewState(stateConstants.TAM_LOGIN);
        } else if (key === urlConstants.SSO_TOKEN) {
          const subDomain = params
            .find((item) => item.includes(urlConstants.SSO_SUB_DOMAIN))
            ?.split("=")[1];
          if (!isEmpty(subDomain)) {
          }
        }
      });
      if (params.length === 0) {
        setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW);
      }
    } else {
      setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW);
    }
  };
  const onInit = async () => {
    const channel = new BroadcastChannel("IMPERSONATION_DATA");
    channel.addEventListener("message", (event) => {
      const impersonateToken = event.data?.accessToken;
      const impersonateRefreshToken = event.data?.refreshToken;
      const subdomain = event.data?.subdomain;

      if (impersonateToken && impersonateRefreshToken) {
        dispatchToken(
          impersonateToken,
          impersonateRefreshToken,
          false,
          subdomain
        );
        return;
      }
    });

    if (token) {
      return;
    }

    // Check if token is available in cookie, if yes then restore Redux state with that token
    const tokenFromCookie = Cookies.get(Constants.ACCESS_TOKEN);
    const refreshTokenFromCookie = Cookies.get(Constants.REFRESH_TOKEN);
    if (!isEmpty(tokenFromCookie) && !isEmpty(refreshTokenFromCookie)) {
      dispatchToken({
        token: tokenFromCookie,
        refreshToken: refreshTokenFromCookie,
      });
      return;
    }

    // Same check for TAM [Tenant Admin Module] (Facial APP)
    const tamToken = Cookies.get(Constants.TAM_ACCESS_TOKEN);
    if (tamToken) {
      dispatchToken({ token: tamToken, isTam: true });
      return;
    }
    // Pre fill subdomain input filed with value in cookie
    const subDomainFromCookie = Cookies.get(Constants.SUBDOMAIN_COOKIE);
    if (subDomainFromCookie && subDomainFromCookie !== "undefined") {
      updateState(subDomainFromCookie, "subDomain");
    }

    //Check if subdomain is provided in url, if not then user must enter it in "subdomain View"
    const subDomain = (() => {
      const isLocalHost = host.includes("localhost");
      const urlParts = host.split(".");
      if (isLocalHost) {
        if (urlParts.length === 0 || urlParts.length !== 2) return "";
        return urlParts[0];
      }
      if (urlParts.length !== 3) return "";
      const subDomainPart = urlParts[0];
      return subDomainPart === "www" ? "" : subDomainPart;
    })();
    if (!isEmpty(subDomain)) {
      updateState(subDomain, "subDomain");
      let authDocResult;
      try {
        authDocResult = await onGetAuthenticationDocument(subDomain);
      } catch (e) {
        authDocResult = {
          error: true,
          message: e.message || e,
        };
      }
      if (authDocResult.isSSO) return; // SSO case
      if (authDocResult.error) {
        return;
      }
    } else {
      setViewState(Constants.LOGIN_VIEW_STATE.CUSTOMER_CODE_VIEW);
      setLoading(false);

      return;
    }
    checkUrlParams();
    setLoading(false);
  };

  const RightView = () => {
    const viewStateConstants = Constants.LOGIN_VIEW_STATE;
    const ComponentMap = {
      [viewStateConstants.CUSTOMER_CODE_VIEW]: SubdomainInput,
      [viewStateConstants.LOGIN_FORM_VIEW]: LoginForm,
      [viewStateConstants.FORGET_PASSWORD_VIEW]: ForgetPassword,
      [viewStateConstants.CHANGE_PASSWORD_VIEW]: ChangePassword,
      [viewStateConstants.SET_PASSWORD_VIEW]: SetPassword,
    };

    const Component = ComponentMap[viewState];

    return (
      <Component
        state={state}
        updateState={updateState}
        setViewState={setViewState}
        onGetAuthenticationDocument={onGetAuthenticationDocument}
        dispatchToken={dispatchToken}
        checkUrlParams={checkUrlParams}
      />
    );
  };

  useEffect(() => {
    onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (token) return <>{children}</>; // if token exist render app
  if (loading) return <>Loading...</>;
  return (
    <EwModal styles={{ maxWidth: 1200 }} removeBackArrow open={true}>
      <Stack {...containerOptions}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          gap={3}
          padding={1}
          width={"70%"}
          borderRight={"2px solid black"}
        >
          {/* Left view*/}

          {/* <img width={130} src="/assets/images/EmployWiseLogo.png" alt="Logo" /> */}
          <EmployWiseIcon
            height={"8rem"}
            width={"8rem"}
            color={palette.primary.main}
          />
          <Typography variant="h1">
            Welcome to <span style={{ color: "#636363" }}>Employ</span>
            <span style={{ color: palette.primary.main }}>Wise</span>
          </Typography>
          <TypeEffect />
          <Stack direction={"row"} gap={5}>
            <img
              width={120}
              src="/assets/images/PlayStoreLogo.png"
              alt="Play store"
            />
            <img
              width={120}
              src="/assets/images/AppleStoreLogo.png"
              alt="Apple Store"
            />
          </Stack>
        </Stack>
        <Stack
          overflow={"auto"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"30%"}
        >
          <AlertMessage
            success={state.successMessage}
            onClose={() => updateState(null, "successMessage")}
          />

          <RightView />
        </Stack>
      </Stack>
    </EwModal>
  );
}

export default AuthenticationWrapper;
