import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useMemo } from "react";
import {
  CheckIcon,
  CheckedCheckIcon,
  IndeterminateCheckIcon,
} from "../../../Library/Assets/Icons";
import { CheckboxPropTypes } from "./types";
import { makeStyles } from "@mui/styles";
import ErrorTooltip from "../../ErrorTooltip";

const useStyles = makeStyles({
  icon: {
    opacity: 1,
  },
  iconDisabled: {
    opacity: 0.3,
  },
});

const Checkbox_1: React.FC<CheckboxPropTypes> = (props: CheckboxPropTypes) => {
  const {
    disabled = false,
    fontVariant = "form_text2",
    indeterminate = false,
    name,
    onChange = () => {},
    value,
    type,
    attributes,
    error = "",
  } = props;

  let { label, visibility = true, required = false } = props;

  /**
   * This condition assigns all necessary attributes to the input
   */
  if (attributes) {
    const { Input, Visibility } = attributes;
    required = Input === "M";
    visibility = Visibility === "D";
    // label = Label || "";
  }

  const classes = useStyles();
  const className = disabled ? classes.iconDisabled : classes.icon;

  const checked: boolean = useMemo(() => {
    if (type !== undefined) return value === type[0];
    return value ? true : false;
  }, [type, value]);

  const onClickEvent = (event: React.BaseSyntheticEvent) => {
    const checked = event.target.checked;
    if (type !== undefined) {
      const trueValue = type[0];
      const falseValue = type[1];
      onChange(checked ? trueValue : falseValue, event.target.name);
    } else {
      onChange(checked, event.target.name);
    }
  };

  return !visibility ? (
    <></>
  ) : (
    <ErrorTooltip title={error}>
      <FormControlLabel
        name={name}
        disabled={disabled}
        control={
          <Checkbox
            required={required}
            checked={checked}
            disabled={disabled}
            icon={<CheckIcon className={className} />}
            checkedIcon={<CheckedCheckIcon className={className} />}
            indeterminate={indeterminate}
            indeterminateIcon={<IndeterminateCheckIcon className={className} />}
            name={name}
            onClick={onClickEvent}
          />
        }
        label={
          typeof label === "string" ? (
            <Typography variant={fontVariant}>{label}</Typography>
          ) : (
            label
          )
        }
      />
    </ErrorTooltip>
  );
};

export default Checkbox_1;
