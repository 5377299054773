import { Actions, ReducerAction } from "../Actions/Actiontypes";

const initialState = {
  Component: null,
};

function SideNavReducer(state: any = initialState, action: ReducerAction) {
  switch (action.type) {
    case Actions.ADD_SIDE_COMPONENT: {
      return {
        ...state,
        Component: action.payload,
      };
    }
    default:
      return state;
  }
}

export default SideNavReducer;
