import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Input,
} from "@mui/material";
import TsxAtomic from "../../Atomic/tsxIndex.ts";

const { Input: AtomicInput } = TsxAtomic;

function PasswordInput({ val, onChange, label, onKeyDown }) {
  const [showPassword, setShowPassword] = useState(false);
  if (!onKeyDown) onKeyDown = () => {};
  return false ? (
    <FormControl sx={{ width: "100%" }} variant="standard">
      <InputLabel htmlFor={label + "password"}>{label}</InputLabel>
      <Input
        id={label + "Password"}
        variant="standard"
        value={val}
        onKeyDown={onKeyDown}
        onChange={(e) => onChange(e.target?.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility Emplowise"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        type={showPassword ? "text" : "password"}
      />
    </FormControl>
  ) : (
    <AtomicInput
      placeholder={label}
      value={val}
      onChange={(value) => onChange(value)}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility Emplowise"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? "text" : "password"}
      fontVariant="form_text1"
      removeHelperText
    />
  );
}

export default PasswordInput;
