import { Link, Stack } from "@mui/material";
import React, { useState } from "react";

import TsxAtomic from "../../Atomic/tsxIndex.ts";
import PasswordInput from "./Password";
import AlertMessage from "../../Card/Messages/AlertMessage";
import { Constants, onKeyPress, performLogin } from "./utils";
import { isEmpty } from "../../Library/Helpers";

const { Input, Button } = TsxAtomic;

function LoginForm({ state, setViewState, dispatchToken }) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onLogin = () => {
    if (isEmpty(userName)) {
      setError(state.inputLabel + " cannot be empty");
      return;
    }
    if (isEmpty(password)) {
      setError("Password cannot be empty");
      return;
    }
    setLoading(true);
    performLogin({ subDomain: state.subDomain, name: userName, password })
      .then((res) => {
        console.log("On login; ", res);
        if (!isEmpty(res.error_description)) {
          setError(res.error_description);
          return;
        }
        const { access_token, refresh_token } = res;

        dispatchToken({
          token: access_token,
          refreshToken: refresh_token,
          subDomain: state.subDomain,
        });
      })
      .catch((e) => {
        console.log("Error is: ", e.response);
        if (e?.response?.data?.errorMessage) {
          setError(e.response.data.errorMessage);
        }
        //  setError(e?.message );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      gap={"1rem"}
      width={"70%"}
    >
      <Stack rowGap={"2rem"} width={"100%"}>
        <Input
          onKeyDown={(e) => onKeyPress(e, onLogin)}
          value={userName}
          onChange={setUserName}
          placeholder={state.inputLabel}
          removeHelperText
          fontVariant="form_text1"
        />
        <PasswordInput
          label={"Password"}
          val={password}
          onChange={setPassword}
        />
      </Stack>
      <Stack
        width={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        rowGap={"0.5rem"}
      >
        <Link
          component="button"
          variant="helper_text_small"
          onClick={() => {
            setViewState(Constants.LOGIN_VIEW_STATE.FORGET_PASSWORD_VIEW);
          }}
        >
          Forgot Password ?
        </Link>
        <Link
          onClick={() => {
            setViewState(Constants.LOGIN_VIEW_STATE.CHANGE_PASSWORD_VIEW);
          }}
          component="button"
          variant="helper_text_small"
        >
          Change Password
        </Link>
        <AlertMessage error={error} onClose={() => setError(null)} />
        <Button
          label="Login"
          onClick={onLogin}
          loading={loading}
          size="small"
          style={{ alignSelf: "center" }}
          fontVariant="form_text1"
        />
      </Stack>
    </Stack>
  );
}

export default LoginForm;
