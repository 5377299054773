import AutoComplete_1, { FunctionAutocomplete } from "./AutoComplete/AutoComplete_1";
import Button_1 from "./Button/Button_1";
import Checkbox_1 from "./Checkbox/Checkbox_1";
import CheckboxGroup_1 from "./CheckboxGroup/CheckboxGroup_1";
import DateInput from "./Date/DateInput";
import Dropdown_1, { FunctionDropdown } from "./Dropdown/Dropdown_1";
import Input_1 from "./Input/Input_1";
import Radio_1 from "./Radio/Radio_1";
import RadioGroup_1 from "./RadioGroup/RadioGroup_1/RadioGroup_1";
import Switch_1 from "./Switch/Switch_1";

const TsxAtomic = {
  Input: Input_1,
  Dropdown: Dropdown_1,
  FunctionDropdown,
  Button: Button_1,
  AutoComplete: AutoComplete_1,
  FunctionAutocomplete,
  Checkbox: Checkbox_1,
  CheckboxGroup: CheckboxGroup_1,
  Radio: Radio_1,
  RadioGroup: RadioGroup_1,
  Switch: Switch_1,
  DatePicker: DateInput,
};

export default TsxAtomic;
