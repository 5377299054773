import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { ThemeProvider, CssBaseline, GlobalStyles } from "@mui/material";
import store from "./Components/Redux/Store";

import { MainTheme } from "./Themes/MainTheme";

const id = document.getElementById("root").dataset.id;
const tk = document.getElementById("root").dataset.tk;
const layout = document.getElementById("root").dataset.layout;
const debug = document.getElementById("root").dataset.debug;
const tenantCode = document.getElementById("root").dataset.tenantcode;

if (debug !== "true") {
  console.log("Debud is : ", debug);
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
}
console.log("From Index: ", tenantCode);
console.log("process.env", process.env);

ReactDOM.render(
  <ThemeProvider theme={MainTheme}>
    <CssBaseline />
    <GlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
        },
        "*, *::before, *::after": {
          boxSizing: "border-box",
        },
      }}
    />
    <Provider store={store}>
      <App
        id={id}
        tk={tk}
        layout_id={layout}
        debug={debug === "true" ? false : true}
        tenantCode={tenantCode}
      />
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
