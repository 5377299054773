import React, { useState } from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
import { DateProps } from "./types";

function DateInput(props: DateProps): JSX.Element {
  const {
    value,
    onChange,
    helperText,
    width,
    label,
    views = ["day", "month", "year"],
    datePickerProps = {},
    textFieldProps = {},
    hideIcon = false,
  } = props;

  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  const getValue = () => {
    if (!value) return new Date();
    return new Date(value);
  };
  const getPlaceholder = (): string => {
    let str = "";
    if (views.includes("day")) str += "DD-";
    if (views.includes("month")) str += "MM-";
    if (views.includes("year")) str += "YYYY";
    return str;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        views={views}
        label={label}
        value={getValue()}
        onChange={(e) => {
          console.log("changes: ", e);

          if (moment.isMoment(e)) {
            setSelectedDate(e);
            onChange(e.startOf("day").utc().valueOf());
          }
        }}
        components={{
          OpenPickerIcon: !hideIcon ? CalendarTodayIcon : () => <></>,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            onClick={() => setOpen(true)}
            variant="standard"
            InputProps={{
              ...params.InputProps,
            }}
            sx={{
              "& .MuiInput-underline:before": { borderBottomColor: "#9e9e9e" },
              "& .MuiInput-underline:hover:before": { borderBottomColor: "#757575" },
              "& .MuiInput-underline:after": { borderBottomColor: "#000" },
              top: "-5px",
              "& +.MuiInputBase-root": {
                marginTop: 0,
              },
              width,
            }}
            inputProps={{
              ...params.inputProps,
              value: selectedDate ? moment(selectedDate).format(getPlaceholder()) : "",
              placeholder: getPlaceholder(),
              sx: {
                "&::placeholder": {
                  fontSize: "14px",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                },
              },
              
            }}
            helperText={helperText}
            {...textFieldProps}
          />
        )}
        {...datePickerProps}
      />
    </LocalizationProvider>
  );
}

export default DateInput;
