import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import TsxAtomic from "../../Atomic/tsxIndex";
import { isEmpty } from "../../Library/Helpers";
import { onKeyPress } from "./utils";
import AlertMessage from "../../Card/Messages/AlertMessage";

const { Input, Button } = TsxAtomic;

function SubdomainInput({
  state,
  updateState,
  onGetAuthenticationDocument,
  checkUrlParams,
}) {
  const [subDomain, setSubDomain] = useState(state.subDomain || "");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onNext = async () => {
    if (isEmpty(subDomain)) return;
    setLoading(true);
    let authDocResult;
    try {
      authDocResult = await onGetAuthenticationDocument(subDomain);
    } catch (e) {
      authDocResult = {
        error: true,
        message: e.message || e,
      };
    }

    if (!authDocResult || authDocResult.error) {
      // -1 for SSO redirection
      setError(authDocResult.message);
      setLoading(false);
    } else if (!authDocResult.isSSO) {
      // setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW);
      checkUrlParams();
      updateState(subDomain, "subDomain");
    }
  };
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      width={"70%"}
      gap={2}
    >
      {/* Right  view*/}
      <Input
        onKeyDown={(e) => onKeyPress(e, onNext)}
        value={subDomain}
        onChange={setSubDomain}
        placeholder={"Customer Code"}
        removeHelperText
        fontVariant="form_text1"
      />
      <AlertMessage error={error} onClose={() => setError(null)} />
      <Button
        label="Next"
        onClick={onNext}
        loading={loading}
        fontVariant="form_text1"
        size="small"
      />
    </Stack>
  );
}

export default SubdomainInput;
