import { Typography } from "@mui/material";
import React from "react";

function Text({ value, variant, color, key, ...otherProps }) {
  console.log("Fro m typeo: ", key);
  return (
    <Typography variant={variant} color={color} {...otherProps}>
      {value}
    </Typography>
  );
}

export default Text;
