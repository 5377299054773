import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";

import Input from "./Input_3";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

function NumberInput({
  name,
  value,
  width,
  onChange,
  placeholder,
  helperText,
  removeHelperText,
  removeIcons,
  disable,
  error,
  errorMsg,
  required,
}) {
  if (!width) width = "3rem";

  useEffect(() => {}, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        position: "relative",
        marginRight: removeIcons ? undefined : "20px",
      }}
    >
      <Input
        name={name}
        width={width}
        value={value}
        type={"number"}
        onChange={(val, name) => {
          onChange(val?.includes(".") ? parseFloat(val) : parseInt(val), name);
        }}
        placeholder={placeholder || "00"}
        helperText={helperText}
        removeHelperText={removeHelperText}
        disable={disable}
        error={error}
        errorMsg={errorMsg}
        required={required}
      />
      {!removeIcons && (
        <Stack sx={{ position: "absolute", right: "-20px", top: "-3px" }}>
          <ArrowDropUp
            fontSize="small"
            sx={{
              marginTop: "2px",
              color: "#0000008A",
              cursor: "pointer",
            }}
            onClick={() => {
              onChange((value || 0) + 1, name);
            }}
          />
          <ArrowDropDown
            fontSize="small"
            sx={{ marginTop: "-5px", color: "#0000008A", cursor: "pointer" }}
            onClick={() => {
              onChange((value || 0) - 1, name);
            }}
          />
        </Stack>
      )}
    </Box>
  );
}

export default React.memo(NumberInput, (prevProps, currProps) => {
  const fields = Object.keys(prevProps);
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log(
        "Rerendered based on: " +
          fields[i] +
          " " +
          (currProps.helperText || currProps.label || currProps.placeholder)
      );
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
