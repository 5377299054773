import React from "react";
import SmallDropdown from "../Dropdown/SmallDropdown";

const MonthsOptions = [
  { label: "Jan", value: "01" },
  { label: "Feb", value: "02" },
  { label: "Mar", value: "03" },
  { label: "Apr", value: "04" },
  { label: "May", value: "05" },
  { label: "Jun", value: "06" },
  { label: "Jul", value: "07" },
  { label: "Aug", value: "08" },
  { label: "Sep", value: "09" },
  { label: "Oct", value: "10" },
  { label: "Nov", value: "11" },
  { label: "Dec", value: "12" },
];

const MonthDroplist = ({ name, value, onChange }) => {
  return (
    <SmallDropdown
      name={name}
      menuItems={MonthsOptions}
      onChange={onChange}
      value={value}
      placeholder={"MMM"}
      width={"4.5rem"}
    />
  );
};

export default MonthDroplist;
