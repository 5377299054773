import { Stack } from "@mui/material";
import React, { useState } from "react";
import PasswordInput from "./Password";
import { LoadingButton } from "@mui/lab";
import { Constants, onKeyPress, setPassword, validatePassword } from "./utils";
import AlertMessage from "../../Card/Messages/AlertMessage";
import { isEmpty } from "../../Library/Helpers";

function SetPassword({ state, setViewState, updateState }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const onSubmit = () => {
    if (isEmpty(newPassword)) {
      setError("New Password Not Entered");
      return;
    }
    if (isEmpty(confirmPassword)) {
      setError("Confirm Password Not Entered");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords Do Not Match");
      return;
    }
    const validationResult = validatePassword(state.authDocument, newPassword);
    if (validationResult.error) {
      setError(validationResult.error[0]);
      return;
    }
    setLoading(true);
    setPassword({
      userId: state.userId,
      subDomain: state.subDomain,
      password: newPassword,
    })
      .then((res) => {
        if (res.Error) {
          setError(res.Error);
          setLoading(false);
          return;
        }

        setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW);
        updateState("Password Set", "successMessage");
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };
  return (
    <Stack maxWidth={"80%"} gap={2}>
      <PasswordInput
        val={newPassword}
        onChange={setNewPassword}
        label={"New Password"}
      />
      <PasswordInput
        val={confirmPassword}
        onChange={setConfirmPassword}
        label={"Confirm Password"}
        onKeyDown={(e) => onKeyPress(e, onSubmit)}
      />
      <AlertMessage error={error} onClose={() => setError(null)} />
      <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>
        Set Password
      </LoadingButton>
    </Stack>
  );
}

export default SetPassword;
