import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { CheckboxGroupPropTypes, CheckboxMenuItem } from "./types";
import Checkbox from "../../Checkbox/Checkbox_1";

const CheckboxGroup_1: React.FC<CheckboxGroupPropTypes> = (
  props: CheckboxGroupPropTypes
) => {
  const {
    label,
    menuItems = [],
    onChange = () => {},
    value,
    name,
    required = false,
    error = false,
    variant = "standard",
    helperText = " ",
    labelFontVariant = "form_h1",
    optionFontVariant = "form_text2",
  } = props;

  const onCheckHandler = (
    checked: boolean | string,
    item: CheckboxMenuItem
  ) => {
    const itemValue = item.value;
    const newValues = [...value];
    if (checked) {
      newValues.push(itemValue);
    } else {
      const index = newValues.findIndex((v) => v === itemValue);
      if (index !== -1) {
        newValues.splice(index, 1);
      }
    }
    onChange(newValues);
  };

  return (
    <FormControl
      name={name}
      required={required}
      error={error}
      component="fieldset"
      variant={variant}
    >
      {label && (
        <FormLabel component="legend">
          <Typography variant={labelFontVariant}>{label}</Typography>
        </FormLabel>
      )}
      <FormGroup sx={{ gap: "0.7rem" }}>
        {menuItems.map((item, index) => (
          <Checkbox
            label={item.label}
            onChange={(checked) => onCheckHandler(checked, item)}
            key={index}
            fontVariant={optionFontVariant}
            disabled={item.disabled}
            value={value.includes(item.value)}
          />
        ))}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CheckboxGroup_1;
