import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

/*
componentList[Array]:[
  {
    label:"Section title",
    Component: Component
    componentAsFunction : Function
  }
]
*/

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "1rem" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const initialProps = {
  componentList: {
    label: "",
    Component: null,
    props: {},
    disable: false,
  },
  defaultOpenTab: 0,
  variant: "big",
  closable: false,
  closeIcon: "closeArrow",
};
function EwTabs(props = initialProps) {
  const { componentList, defaultOpenTab, variant, closable, closeIcon } = props;
  const [value, setValue] = React.useState(defaultOpenTab || 0);
  console.log("EWtabs: ", componentList);
  const getTabIcon = (index) => {
    if (closable) {
      if (closeIcon === "closeArrow")
        return value === index ? <ArrowDropDown /> : <ArrowDropUp />;
    }
    return undefined;
  };
  const handleChange = (event, newValue) => {
    if (closable && value === newValue) {
      setValue(null);
    } else {
      setValue(newValue);
    }
  };
  if (!componentList) return <></>;
  return (
    <Box>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={
            variant === "big"
              ? { border: 0, minHeight: "48px" }
              : variant === "small"
              ? { border: 0 }
              : {}
          }
        >
          {componentList.map((ele, index) => {
            console.log(value, `simple-tab-${index}`, "VALUE IS IT");
            return (
              <Tab
                onClick={() => {
                  if (closable && value === index) {
                    setValue(null);
                  }
                }}
                icon={getTabIcon(index)}
                iconPosition={closable ? "start" : undefined}
                disabled={ele.disable}
                label={
                  <Typography
                    variant="button"
                    sx={{
                      textTransform: "none",
                      color: ele.disable && "#8f8f8f",
                    }}
                  >
                    {ele.label}
                  </Typography>
                }
                style={{
                  backgroundColor:
                    value === index && ele.disable ? "#ebebeb" : "",
                  minWidth: 0,
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                  padding:
                    variant === "big"
                      ? "16px"
                      : variant === "small"
                      ? "5px"
                      : "",
                  borderRadius: variant === "big" ? "4px" : "",
                }}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      {componentList.map((ele, index) => {
        if (!ele.props) ele.props = {};
        if (!ele.args) ele.args = [];
        return (
          <TabPanel value={value} index={index}>
            {ele.disable ? (
              <>
                {ele.disableComponent ? (
                  <ele.disableComponent />
                ) : (
                  <Typography variant="subtitle1">
                    This tab is disabled
                  </Typography>
                )}
              </>
            ) : ele.Component ? (
              <ele.Component {...ele.props} />
            ) : (
              ele.componentAsFunction(...ele.args)
            )}
          </TabPanel>
        );
      })}
    </Box>
  );
}

export default EwTabs;
