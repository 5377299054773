import React, { useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Atomic from "../../Atomic";
import {
  Constants,
  changePassword,
  onKeyPress,
  validatePassword,
} from "./utils";
import PasswordInput from "./Password";
import { isEmpty } from "../../Library/Helpers";
import AlertMessage from "../../Card/Messages/AlertMessage";

const { Input } = Atomic;

function ChangePassword({ state, setViewState, updateState }) {
  const [userName, setUserName] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSubmit = () => {
    if (isEmpty(userName)) {
      setError(state.inputLabel + " Not Entered");
      return;
    }
    if (isEmpty(oldPassword)) {
      setError("Old Password Not Entered");
      return;
    }
    if (isEmpty(newPassword)) {
      setError("New Password Not Entered");
      return;
    }
    if (isEmpty(confirmPassword)) {
      setError("Confirm Password Not Entered");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords Do Not Match");
      return;
    }
    const validationResult = validatePassword(state.authDocument, newPassword);
    if (validationResult.error) {
      setError(validationResult.error[0]);
      return;
    }
    setLoading(true);
    changePassword({
      userName,
      subDomain: state.subDomain,
      password: newPassword,
      oldPassword,
    })
      .then((res) => {
        if (res.Error) {
          setError(res.Error);
          setLoading(false);
          return;
        }
        updateState("Password Changed", "successMessage");
        setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  return (
    <Stack width={"70%"} gap={2}>
      <Stack alignItems={"flex-start"} width={"100%"}>
        <IconButton
          onClick={() =>
            setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW)
          }
        >
          <ArrowBackIosIcon />
          <Typography>Back</Typography>
        </IconButton>
      </Stack>
      <Input label={state.inputLabel} value={userName} onChange={setUserName} />
      <PasswordInput
        label={"Old Password"}
        val={oldPassword}
        onChange={setOldPassword}
      />
      <PasswordInput
        label={"New Password"}
        val={newPassword}
        onChange={setNewPassword}
      />
      <PasswordInput
        label={"Confirm Password"}
        val={confirmPassword}
        onChange={setConfirmPassword}
        onKeyDown={(e) => onKeyPress(e, onSubmit)}
      />
      <AlertMessage error={error} onClose={() => setError(null)} />
      <LoadingButton onClick={onSubmit} loading={loading} variant="contained">
        Change Password
      </LoadingButton>
    </Stack>
  );
}

export default ChangePassword;
