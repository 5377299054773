import { Actions, ReducerAction } from "../Actions/Actiontypes";

const initialState = {};

function updateKeyValue(
  obj: { [key: string]: any },
  keyValuePairs: { value: any; type: string }[]
): { [key: string]: any } {
  const objCopy = { ...obj };
  let tempObj = objCopy;
  for (const pair of keyValuePairs) {
    const { value, type } = pair;
    const keys = type.split(".");
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (i === keys.length - 1) {
        tempObj[key] = value;
      } else {
        if (tempObj[key] === undefined) tempObj[key] = {};
        tempObj[key] = Array.isArray(tempObj[key])
          ? [...tempObj[key]]
          : { ...tempObj[key] };
        tempObj = tempObj[key];
      }
    }
  }
  return objCopy;
}

function DataReducer(state: any = initialState, action: ReducerAction) {
  switch (action.type) {
    case Actions.SET_DATA: {
      return {
        ...state,
        [action.payload.id]: action.payload.data,
      };
    }
    case Actions.UPDATE_PROPS: {
      let {
        id,
        componentNumber,
        data,
        props,
        append,
        updateAllProps,
        fieldName,
      } = action.payload;

      if (fieldName) componentNumber = fieldName;
      if (updateAllProps) {
        if (!state[id]) state[id] = {};
        if (!state[id][fieldName]) state[id][fieldName] = {};
        state[id][fieldName] = data;
        console.log("Inside update all props: ", state);
        return state;
      }

      if (append) {
        if ((!state[id] || !state[id][componentNumber]) && props) return state;
        state[id][componentNumber][props] = {
          ...state[id][componentNumber][props],
          data,
        };
        return JSON.parse(JSON.stringify(state));
      }
      if (!props) {
        if (!state[id]) state[id] = {};
        state[id][componentNumber] = data;
      } else {
        if (!state[id]) return state;
        console.log("state is : ", state[id], " ", componentNumber);

        if (!state[id][componentNumber]) state[id][componentNumber] = {};

        state[id][componentNumber][props] = data;
      }
      console.log("From gerral reducer", state);
      return JSON.parse(JSON.stringify(state));
    }
    case Actions.UPDATE_ALL_FIELDS: {
      const { id, data } = action.payload;
      state[id] = { ...JSON.parse(JSON.stringify(state[id] || {})), ...data };
      return { ...state };
    }
    case Actions.UPDATE_KEYVALUE: {
      const { id, key, value } = action.payload;
      const stateCopy = updateKeyValue(state[id], [{ value, type: key }]);
      state[id] = stateCopy;
      return { ...state };
    }
    case Actions.UPDATE_KEYVALUE_PAIRS: {
      const { id, keyValuePairs } = action.payload;
      const stateCopy = updateKeyValue(state[id], keyValuePairs);
      state[id] = stateCopy;
      return { ...state };
    }
    case Actions.UNSET_DATA: {
      let data = state;
      delete data[action.payload.id];
      return data;
    }
    default:
      return state;
  }
}

export default DataReducer;
