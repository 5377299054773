import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

import Atomic from "../../Atomic";
import AlertMessage from "../../Card/Messages/AlertMessage";
import { Constants, onKeyPress, performLogin } from "./utils";
import { isEmpty } from "../../Library/Helpers";

const { Input } = Atomic;

function ForgetPassword({ state, setViewState }) {
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSendLink = () => {
    setError(null);
    if (isEmpty(userName)) {
      setError(state.inputLabel + " Cannot be empty");
      return;
    }
    setLoading(true);
    performLogin({
      name: userName,
      mode: Constants.PAYLOAD_MODE.SEND_EMAIL,
      subDomain: state.subDomain,
    })
      .then((res) => {
        if (res.Error) {
          setError(res.Error);
          setLoading(false);
          return;
        }
        setSuccess("Email Sent");
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Stack width={"100%"} alignItems={"flex-start"}>
        <IconButton
          onClick={() =>
            setViewState(Constants.LOGIN_VIEW_STATE.LOGIN_FORM_VIEW)
          }
        >
          <ArrowBackIosIcon />
          <Typography>Login</Typography>
        </IconButton>
      </Stack>
      <Stack width={"70%"} gap={2}>
        <Input
          onKeyDown={(e) => onKeyPress(e, onSendLink)}
          label={state.inputLabel}
          value={userName}
          onChange={setUserName}
        />
        <AlertMessage error={error} onClose={() => setError(null)} />
        <AlertMessage success={success} onClose={() => setSuccess(null)} />

        <LoadingButton
          onClick={onSendLink}
          variant="contained"
          loading={loading}
        >
          Send Reset Link
        </LoadingButton>
      </Stack>
    </>
  );
}

export default ForgetPassword;
