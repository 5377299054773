import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown_3";
import {
  getCountry,
  getTimezones,
  getLeType,
} from "../../Library/Api/Services";
import AlertMessage from "../../Card/Messages/AlertMessage";

const initialProps = {
  name: "",
  type: "", // [C(country),R(region),T(Timezones),"L"(legalentity)]
  value: "",
  onChange: () => {},
  dropDownProps: {},
  country: "",
  label: "",
  placeholder: "",
  typeFromLayout: "", // same as type, but when send through layout json, [to avoid naming conflict in layout json]
};

function UtilsDropdown(props = initialProps) {
  const {
    name,
    value,
    onChange,
    label,
    country,
    typeFromLayout,
    width,
    getLabel,
    removeHelperText,
    placeholder,
  } = props;
  let { type, dropDownProps } = props;
  if (typeFromLayout) type = typeFromLayout;

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  if (!dropDownProps) dropDownProps = {};

  const typeMapper = {
    C: {
      api: () => getCountry({ mode: "CLC" }),
      resultHandler: (data) => {
        if (data?.ReturnData?.length > 0) {
          return data.ReturnData.map((ele) => ({
            label: ele["Englishname"],
            value: ele["Alpha2Code"],
          }));
        } else {
          return ["No Data"];
        }
      },
    },
    // R: {
    //   api: () => {
    //     if (!country) {
    //       setError("Country not selected");
    //       return;
    //     }
    //     getRegionByCountry({ countryCode: country });
    //   },
    //   mapper: {
    //     label: "",
    //     value: "",
    //   },
    // },
    T: {
      api: getTimezones,
    },
    L: {
      api: () => getLeType({ countryId: country }),
      resultHandler: (data) => {
        if (data?.LegalEntities) {
          return data.LegalEntities.map((ele) => ({
            label: ele.LegalEntityName,
            value: ele.LegalEntityID,
          }));
        }
      },
    },
  };

  useEffect(() => {
    const { api, resultHandler } = typeMapper[type];
    api()
      ?.then((res) => {
        setData(resultHandler ? resultHandler(res) : res.ReturnData || res);
      })
      .catch((e) => {
        setError(e.message || e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!type) return <>Dropdown Error type missing</>;
  if (type === "L" && !country) return <>Missing params</>;
  if (loading) return <>Loading..</>;
  if (error) return <AlertMessage error={error} />;
  return (
    <Dropdown
      name={name}
      menuItems={data}
      label={label}
      value={value}
      onChange={onChange}
      width={width}
      {...dropDownProps}
      removeHelperText={removeHelperText}
      placeholder={placeholder}
    />
  );
}

export default UtilsDropdown;
