import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@mui/material";

import { Constants } from "./utils";

function TypeEffect() {
  const strings = Constants.TYPE_EFFECT_STRING_LIST;

  const [str, setStr] = useState("");

  const isMountedRef = useRef(true); // Create a mutable ref

  useEffect(() => {
    function typeNextLetter(
      currentStringIndex,
      currentLetterIndex,
      currentString
    ) {
      let timmer = 100;

      const string = strings[currentStringIndex];

      currentString += string.charAt(currentLetterIndex);
      setStr(currentString);
      currentLetterIndex += 1;
      if (currentLetterIndex >= string.length) {
        currentStringIndex += 1;
        currentLetterIndex = 0;
        currentString = "";
        timmer = 2000;
      }

      if (currentStringIndex >= strings.length) {
        currentStringIndex = 0;
        currentLetterIndex = 0;
        currentString = "";
      }

      setTimeout(() => {
        typeNextLetter(currentStringIndex, currentLetterIndex, currentString);
      }, timmer);
    }

    // Start typing the first string

    typeNextLetter(0, 0, "");

    return () => {
      // Set the isMountedRef to false in the cleanup function
      isMountedRef.current = false;
    };
  }, []);
  return (
    <div>
      <Typography variant="h4" color={"primary"}>
        {str}
        <span className="blink_me">{" |"}</span>
      </Typography>
    </div>
  );
}

export default TypeEffect;
