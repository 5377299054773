import React from "react";
import AutoComplete_1 from "./AutoComplete_1";
import withFunction from "./withFunction";
import { AutoCompletePropTypes, FunctionAutoCompletePropTypes } from "./types";
import { ServiceFunctionLibraryType } from "../../../Library/Api/types";

const reactMemo: <T>(
  c: T,
  propsAreEqual: (prev: any, curr: any) => boolean
) => T = React.memo;

/**
 * @function withFunction is a Higher Order Component which is used to give any get function capabilites
 * to the Autocomplete component
 */
export const FunctionAutocomplete = reactMemo(
  withFunction(AutoComplete_1),
  (prevProps, currProps): boolean => {
    const fields = Object.keys(
      prevProps
    ) as (keyof (FunctionAutoCompletePropTypes & ServiceFunctionLibraryType))[];
    for (let i = 0; i < fields.length; i++) {
      if (prevProps[fields[i]] !== currProps[fields[i]]) {
        console.log(
          "Rerendered based on: " +
            fields[i] +
            " " +
            (currProps.placeholder || currProps.helperText)
        );
        return false; // meaning, the component should rerender;
      }
    }
    return true;
  }
);

export default reactMemo(AutoComplete_1, (prevProps, currProps): boolean => {
  const fields = Object.keys(prevProps) as (keyof AutoCompletePropTypes)[];
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log(
        "Rerendered based on: " +
          fields[i] +
          " " +
          (currProps.placeholder || currProps.helperText)
      );
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
