import { Action } from "redux";

export const Actions = {
  SET_DATA: "SET_DATA",
  UPDATE_PROPS: "UPDATE_PROPS",
  UPDATE_ALL_FIELDS: "UPDATE_ALL_FIELDS",
  UPDATE_KEYVALUE: "UPDATE_KEYVALUE",
  UNSET_DATA: "UNSET_DATA",
  ADD_SIDE_COMPONENT: "ADD_SIDE_COMPONENT",
  ADD_AUTH_DATA: "ADD_AUTH_DATA",
  UPDATE_KEYVALUE_PAIRS: "UPDATE_KEYVALUE_PAIRS",
} as const;

type ActionValues = (typeof Actions)[keyof typeof Actions];

export interface ReducerAction extends Action<any> {
  type: ActionValues;
  payload: any;
}
