import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";

const initialProps = {
  name: "",
  label: null,
  value: null,
  onChange: null,
  disable: null,
  fontVariant: "form_h1",
  size: "small",
  required: false,
  error: false,
  errorMsg: "",
};

export default function EwSwitch(props = initialProps) {
  const {
    name,
    label,
    value,
    onChange,
    disable,
    fontVariant,
    size,
    required,
    error,
    errorMsg,
  } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            size={size || "medium"}
            checked={value}
            onChange={(e) => onChange(e.target.checked, e.target.name)}
            disabled={disable}
            name={name}
          />
        }
        {...props}
        sx={{ ...props.sx, marginRight: 0 }}
        label={
          <Typography
            variant={fontVariant || "form_h1"}
            sx={{ display: "flex", alignItems: "flex-start", gap: "0.5rem" }}
          >
            {label}{" "}
            {error && (
              <Typography
                variant={"helper_text"}
                color={"error"}
                sx={{ alignSelf: "center" }}
                fontWeight={600}
              >
                ({errorMsg})
              </Typography>
            )}
            {required && (
              <Typography variant={fontVariant || "form_h1"} color={"error"}>
                *
              </Typography>
            )}
          </Typography>
        }
      />
    </FormGroup>
  );
}
