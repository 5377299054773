import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  CheckIcon,
  CheckedCheckIcon,
  IndeterminateCheckIcon,
} from "../../Library/Assets/Icons";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const initOptions = {
  name: "",
  label: null,
  value: null,
  onChange: null,
  disabled: null,
  formGroupStyles: {},
  typographyVariant: "",
  type: 1, // 1-> return true/false,  2-> returns E (for true)/D (for false)
  indeterminate: false,
};

const useStyles = makeStyles({
  icon: {
    opacity: 1,
  },
  iconDisabled: {
    opacity: 0.3,
  },
});

function Checkboxes(props = initOptions) {
  const classes = useStyles();
  let {
    name,
    label,
    value,
    onChange,
    disabled,
    formGroupStyles,
    typographyVariant,
    type,
    indeterminate,
  } = props;

  if (!formGroupStyles) formGroupStyles = {};
  if (type === 2) {
    value = value === "E";
  }
  const onCheck = (e) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
    if (type === 2) {
      onChange(isChecked ? "E" : "D", e.target.name);
    } else {
      onChange(isChecked, e.target.name);
    }
  };
  return (
    <FormGroup style={formGroupStyles}>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            checked={value}
            disabled={disabled}
            icon={
              <CheckIcon
                className={disabled ? classes.iconDisabled : classes.icon}
              />
            }
            checkedIcon={
              <CheckedCheckIcon
                className={disabled ? classes.iconDisabled : classes.icon}
              />
            }
            indeterminate={indeterminate}
            indeterminateIcon={
              <IndeterminateCheckIcon
                className={disabled ? classes.iconDisabled : classes.icon}
              />
            }
            name={name}
            onClick={disabled ? () => {} : onCheck}
          />
        }
        label={<Typography variant={typographyVariant}>{label}</Typography>}
      />
    </FormGroup>
  );
}

export default React.memo(Checkboxes, (prevProps, currProps) => {
  const fields = Object.keys(prevProps);
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log(
        "Rerendered based on: " +
          fields[i] +
          " " +
          (currProps.helperText || currProps.label || currProps.placeholder)
      );
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
