import React from "react";
import { Box, IconButton, Modal, Paper, Stack } from "@mui/material";
import { v4 as uuid } from "uuid";
import { BackIcon, CrossIcon } from "../../Library/Assets/Icons";

const initialProps = {
  open: null,
  onClose: () => {}, // this function is called when cross is clicked
  styles: {},
  onBack: () => {}, // this function  is called when back arrow is clicked
  maxHeight: 700,
  maxWidth: 1100,
};
function EwModal(props = initialProps) {
  let { children, open, onClose, styles, onBack, maxHeight, maxWidth } = props;
  styles = styles || {};

  const id = uuid();
  if (!open) open = false;
  function clicked(e) {
    console.log(e.target);
    if (e.target === document.getElementById(id)) {
      if (onClose) onClose();
      if (onBack) onBack();
    }
  }

  return (
    <>
      <Modal open={open} onClose={onClose || onBack}>
        <div
          id={id}
          onClick={clicked}
          className="Flex"
          style={{ height: "100%" }}
        >
          <Box position={"relative"}>
            <Paper
              sx={{
                position: "relative",
                maxHeight: maxHeight || 700,
                overflow: "auto",
                maxWidth: maxWidth || 1100,
                ...styles,
              }}
              className="scroll"
            >
              <Stack direction={"row"}>
                {onBack && (
                  <Stack>
                    <IconButton onClick={onBack}>
                      <BackIcon />
                    </IconButton>
                  </Stack>
                )}
                <div>{children}</div>
              </Stack>
            </Paper>
            {onClose && (
              <Stack sx={{ position: "absolute", top: 5, right: 15 }}>
                <IconButton onClick={onClose}>
                  <CrossIcon />
                </IconButton>
              </Stack>
            )}
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default EwModal;
