import React, { useEffect, useMemo, useState } from "react";
import { AutoCompletePropTypes, FunctionAutoCompletePropTypes } from "./types";
import { ServiceFunctionLibraryType } from "../../../Library/Api/types";
import { serviceFunctionLibrary } from "../../../Library/Helpers";

/**
 * This is a Higher Order Component (HOC) which adds GetData functionality for a given Autocomplete.
 *
 * @param {React.FC<AutoCompletePropTypes>} AutoComplete
 * @returns Returns the Autocomplete component with GetData functionality.
 */
const withFunction = (AutoComplete: React.FC<AutoCompletePropTypes>) => {
  return (
    props: FunctionAutoCompletePropTypes & ServiceFunctionLibraryType
  ) => {
    const { functionName, params, filter, ...autocompleteProps } = props;

    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    /**
     *
     * @param {any} item
     * @returns Boolean. Whether a document in the ReturnData of getData function needs to be filtered out or not
     */
    function filterData(item: any) {
      if (filter) return filter(item);
      return true;
    }

    const serviceFunctionLibraryProps = useMemo(() => {
      return {
        functionName: functionName,
        params: params,
      } as ServiceFunctionLibraryType;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
      if (functionName) {
        setLoading(true);
        serviceFunctionLibrary(serviceFunctionLibraryProps)
          .then((res: any) => {
            const data = res.ReturnData;
            if (data && Array.isArray(data) && data.length > 0) {
              setData(
                data
                  .map((d: any) => ({
                    ...d,
                  }))
                  .filter(filterData)
              );
            }
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceFunctionLibraryProps]);

    return (
      <AutoComplete {...autocompleteProps} options={data} loading={loading} />
    );
  };
};

export default withFunction;
