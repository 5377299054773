import React from "react";
import { ButtonPropTypes } from "./types";
import { Typography } from "@mui/material";
import { LoadingButton as Button } from "@mui/lab";

/**
 * Custom Button created from MUI Button
 *
 * @param {ButtonPropTypes} props
 * @returns Button component
 */
const Button_1: React.FC<ButtonPropTypes> = (props: ButtonPropTypes) => {
  const {
    onClick,
    startIcon,
    endIcon,
    size,
    variant = "contained",
    disable = false,
    style = {},
    color = "primary",
    label,
    loading = false,
    fontVariant = "button_medium",
    fontWeight = 400,
  } = props;

  return (
    <Button
      sx={{ ...style, textTransform: "none" }}
      color={color}
      onClick={onClick}
      disabled={disable}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      loading={loading}
    >
      <Typography variant={fontVariant} fontWeight={fontWeight}>
        {label}
      </Typography>
    </Button>
  );
};

export default Button_1;
