import { serverName } from "./env";

const tsWindow: any = window;
const PRODUCTION = tsWindow.Production;
const PRODUCTION_BASE_URL = "https://internal.employwise.app";

let FUNCTION_HANDLER_URL =
  "https://internal.employwise-sandbox.in/ui-function-handler/function";
let SERVICE_CALL_TEST_URL =
  "https://internal.employwise-sandbox.in/ui-request/service-call";
let SERVICE_CALL_PRODUCTION_URL =
  "https://api.employwise.tech/ui-request/service-call";
let AUTH_URL =
  "http://keycloakserviceapplication-env.eba-dmpea2is.ap-south-1.elasticbeanstalk.com/keycloakservice/login";
let DOCUMENT_MANAGER =
  "https://internal.employwise-sandbox.in/document-manager/document?datasource=internal";
let FILE_UPLOAD =
  "http://uifunctionhandler-env.eba-2u7kzzfb.ap-south-1.elasticbeanstalk.com/ui-function-handler/file/upload";

if (PRODUCTION) {
  FUNCTION_HANDLER_URL = PRODUCTION_BASE_URL + "/functionHandler";
  AUTH_URL = PRODUCTION_BASE_URL + "/keycloak/login";
}

const URLs = {
  FUNCTION_HANDLER_URL,
  PRODUCTION,
  AUTH_URL,
  DOCUMENT_MANAGER,
  FILE_UPLOAD,
  SERVICE_CALL_URL:
    serverName === "Production"
      ? SERVICE_CALL_PRODUCTION_URL
      : SERVICE_CALL_TEST_URL,
};

export default URLs;
