import SmallDropdown, { SmallInput } from "../Dropdown/SmallDropdown";

export const YearInput = ({ name, value, onChange }) => {
  return (
    <SmallInput
      name={name}
      type="number"
      onChange={(e) => {
        const val = e.target.value;
        onChange(val.toString(), e.target.name);
      }}
      value={value ? +value : null}
      sx={{ width: "3.2rem" }}
      placeholder="YYYY"
    />
  );
};

export const YearDroplist = ({ name, value, onChange }) => {
  const YearOptions = Array.from({ length: 25 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  );
  return (
    <SmallDropdown
      name={name}
      menuItems={YearOptions}
      onChange={onChange}
      value={value}
      width={"4.5rem"}
      placeholder={"YYYY"}
    />
  );
};
