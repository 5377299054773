import { useState } from "react";

type Generic = {
  [key: string]: any;
};

function updateKeyValue<T extends Generic>(
  obj: T,
  keyValuePairs: { value: any; type: string }[]
): T {
  const objCopy = { ...obj } as T;

  for (const pair of keyValuePairs) {
    let tempObj: Generic = objCopy;
    const { value, type } = pair;
    const keys = type.split(".");
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (i === keys.length - 1) {
        tempObj[key] = value;
      } else {
        if (tempObj[key] === undefined) tempObj[key] = {};
        tempObj[key] = Array.isArray(tempObj[key])
          ? [...tempObj[key]]
          : { ...tempObj[key] };
        tempObj = tempObj[key];
      }
    }
  }
  return objCopy;
}

function useStateUpdater<T extends Generic>(
  initialState: Partial<T>
): [
  T,
  (value: any, type: string) => void,
  (data: Partial<T>) => void,
  (keyValuePairs: { value: any; type: string }[]) => void
] {
  if (!initialState) initialState = {} as T;
  const [state, setState] = useState<Partial<T>>(initialState);

  const updateState = (value: any, key: string) => {
    console.log("Before setting", key, " ", value);

    // key can be "key1.key2.key3" and also "key.0.key2" for arrays
    if (key?.includes(".")) {
      setState((prevState: Partial<T>) => {
        const stateCopy = updateKeyValue(prevState, [{ value, type: key }]);
        return stateCopy;
      });
    } else {
      setState((prev: Partial<T>) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const updateStore = (data: Partial<T>) => {
    setState((prevState) => ({ ...prevState, ...data }));
  };

  const updateKeyValues = (keyValuePairs: { value: any; type: string }[]) => {
    setState((prevState: Partial<T>) => {
      const stateCopy = updateKeyValue(prevState, keyValuePairs);
      return stateCopy;
    });
  };

  return [state as T, updateState, updateStore, updateKeyValues];
}

export default useStateUpdater;
