import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import React from "react";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    border: `1.5px solid ${theme.palette.error.main}`,
    ...theme.typography.caption,
  },
}));

const ErrorTooltip: React.FC<TooltipProps> = (props): JSX.Element => {
  return <HtmlTooltip {...props} placement="top-start" />;
};

export default ErrorTooltip;
