import { Actions, ReducerAction } from "../Actions/Actiontypes";

const initialState = {
  Token: null,
  tenantCode: null,
  userId: "",
  userData: {},
  otherTokens: {},
  refreshToken: "",
};

function AuthReducer(state: any = initialState, action: ReducerAction) {
  switch (action.type) {
    case Actions.ADD_AUTH_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

export default AuthReducer;
