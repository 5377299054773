import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

const map = {
  0: "success",
  1: "error",
  2: "info",
  3: "warning",
};

function AlertMessage(
  props = {
    type: null,
    message: null,
    onClose: null,
    autoClose: false,
    autoCloseTime: 3000,
    error: null,
    removeBreak: false,
    success: null,
  }
) {
  const { message, onClose, autoClose, autoCloseTime, error, success } = props;
  let { type } = props;

  if (!type && type !== 0) type = 1;
  if (success) type = 0;

  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        onClose();
      }, autoCloseTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  if (!(error || success)) return <></>;
  return (
    <>
      <Alert onClose={onClose} severity={map[type]}>
        {message || error || success}
      </Alert>
    </>
  );
}

export default AlertMessage;
