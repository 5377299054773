import { FormControlProps, TypographyProps } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { AttributeType } from "../../../Library/Helpers/DocumentManager/types";
/**
 * Type of each menuItem in the MenuItems array passed to the Dropdown
 */
export interface DropdownMenuItem {
  /**
   * Label of each item
   */
  label: string;
  /**
   * Value of the respective menuItem
   */
  value: string | number;
  /**
   * Option to disable a menuItem in the Dropdown
   */
  disable?: boolean;
}
/**
 * Props for the Dropdown component
 */
export interface DropdownPropTypes extends React.PropsWithChildren {
  /**
   * Name is used to identify the dropdown uniquely. Also used to pass the fieldName for the onChange function
   */
  name?: string;
  /**
   * Used when he helperText should be something different from the <b>Placeholder</b>.
   * @default 'placeholder' will be the default for helperText
   */
  helperText?: string;
  /**
   * MenuItems can be an array of strings which will be eventually converted into an array of [label,value].
   * When using GetDataDropdown, this is passed from the HOC
   */
  menuItems?:
    | string[]
    | DropdownMenuItem[]
    | { [key: string]: string[] | DropdownMenuItem[] };
  /**
   * This is a callback which is used to get the selected option.
   * @param {string | number} value The selected value
   * @param {string | undefined} type The name prop passed to the dropdown used as type in the updateState function if used
   * @param {string} label The label of the option that is selected.
   * @returns {void}
   */
  onChange: (
    value: string | number,
    type: string | undefined,
    label: string
  ) => void;
  /**
   * This is a callback which is invoked when the Dropdown is <b>blurred</b>
   * @param {string | number} value The selected value
   * @param {string | undefined} type The name prop passed to the dropdown used as type in the updateState function if used
   * @param {string} label The label of the option that is selected.
   * @returns {void}
   */
  onBlur?: (
    value: string | number,
    type: string | undefined,
    label: string
  ) => void;
  /**
   * Is the value seleced in the dropdown
   */
  value: string | number | null;
  /**
   *  Option to disable a dropdown which will be grayed out.
   */
  disable?: boolean;
  /**
   *  For adding additional styles
   */
  styles?: React.CSSProperties;
  /**
   *  Shows a sign that says this dropdown is required
   */
  required?: boolean;
  /**
   * To change the dropdown arrow with the custom arrow
   */
  popperArrow?: React.FC;
  /**
   *  Option to disable the helper text
   */
  removeHelperText?: boolean;
  /**
   * Input variant used for the dropdown
   */
  variant?: SelectInputProps["variant"];
  /**
   * Typography variant used for labels and select label.
   */
  fontVariant?: TypographyProps["variant"];
  /**
   * Placeholder
   */
  placeholder?: string;
  /**
   * Overall size of the dropdown.
   */
  size?: FormControlProps["size"];
  /**
   * These are the styles for the Form Control.
   */
  formStyles?: React.CSSProperties;
  /**
   * Overall width.
   */
  width?: string;
  /**
   * Height of the Paper component in the menu.
   */
  menuPaperHeight?: string;
  /**
   * This represents if there is an error.
   */
  error?: string;
  /**
   * Always shows the helper text even if the value is empty.
   * @default false
   */
  showHelperText?: boolean;
  helperTextFontVariant?: TypographyProps["variant"];
  /**
   * This is a custom component which is used to display the value that is selected
   */
  OptionComponent?: React.FC<{ menuItem: DropdownMenuItem }>;
  /**
   * This is a custom component which is used to display the value that is selected
   */
  ValueComponent?: React.FC<{ label: string; value: string | number }>;
  /**
   * This prop is used to remove the selecion functionality inorder to use it as select only dropdown
   */
  selectOnly?: boolean;
  /**
   * All attributes in one single prop
   */
  attributes?: AttributeType;
  /**
   * Determines the visiblity of the Dropdown
   */
  visibility?: boolean;
}
/**
 * Mapper used to map label to value in the getData function
 */
export interface FunctionMapperType {
  /**
   * Label is the fieldName of the field that should be represented as a label.
   * Label is also a callback function which gets the document corresponding to each option and a string is returned which represents the label
   */
  label: string | ((document: any) => string);
  /**
   * Value is the fieldName of the field that should be represented as a value for an option
   */
  value: string;
}

export interface FunctionDropdownPropTypes
  extends Omit<
    DropdownPropTypes,
    "onChange" | "OptionComponent" | "ValueComponent"
  > {
  mapper: FunctionMapperType;
  filter?: (item: any) => boolean;
  onChange: (
    value: string | number,
    type?: string | undefined,
    label?: string,
    document?: any
  ) => void;
  OptionComponent?: React.FC<{ document: any }>;
  ValueComponent?: React.FC<{ document: any }>;
  groupBy?: (data: any[]) => { [key: string]: any[] };
}

export function isHeaderItem(
  item: { header: string } | DropdownMenuItem
): item is { header: string } {
  return (item as { header: string }).header !== undefined;
}
