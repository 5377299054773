import React, { useCallback, useEffect, useRef, useState } from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import DateInput from "./DateInput";
import MonthDroplist from "./MonthDroplist";
import { YearInput } from "./YearInput";
import useStateUpdater from "../../Library/Hooks/useStateUpdater";
import moment from "moment";

export function getEpochFromDate(dateObject) {
  const { date, month, year } = dateObject;
  const dateObj = new Date(parseInt(year), parseInt(month) - 1, parseInt(date));
  return dateObj.getTime();
}

export function getDateFromEpoch(epoch) {
  var dateObj = new Date(epoch);
  return {
    date: dateObj.getDate().toString().padStart(2, "0"),
    month: (dateObj.getMonth() + 1).toString().padStart(2, "0"),
    year: dateObj.getFullYear().toString(),
  };
}

function DatePicker({ label, fontVariant, name, value, onChange }) {
  const [date, updateDate, updateStore] = useStateUpdater({
    date: null,
    month: null,
    year: null,
  });
  const [calendarDate, setCalendarDate] = useState(null);

  const [open, setOpen] = useState(false);
  const textFieldRef = useRef(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    if (value) {
      const dateObject = getDateFromEpoch(value);
      console.log(value, dateObject, "EffectiveFromDate");
      updateStore(dateObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (date.date && date.month && date.year) {
      const epoch = getEpochFromDate(date);
      setCalendarDate(new Date(epoch).toLocaleString());
      onChange(epoch, name, date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      textFieldRef.current &&
      !textFieldRef.current.contains(event.target) &&
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const updateState = useCallback((value, type) => {
    updateDate(value, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {open && (
          <MuiDatePicker
            views={["day", "month", "year"]}
            value={calendarDate}
            label={"Calendar"}
            onChange={(value) => {
              if (value) {
                setCalendarDate(value);
                const date = moment(new Date(value));
                updateStore({
                  date: date.format("DD"),
                  month: date.format("MM"),
                  year: date.format("YYYY"),
                });
              }
            }}
            renderInput={(params) => {
              return <></>;
            }}
            PopperProps={{
              anchorEl: textFieldRef.current,
            }}
            open={open}
            onClose={() => setOpen(false)}
            disabled={false}
          />
        )}
        <Stack rowGap={"0.2rem"}>
          {label && (
            <Typography variant={fontVariant || "form_text3"}>
              {label}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
            ref={textFieldRef}
          >
            <DateInput
              name={"date"}
              value={date.date}
              onChange={updateState}
              month={+date.month}
              year={+date.year}
            />
            <MonthDroplist
              name={"month"}
              value={date.month}
              onChange={updateState}
            />
            <YearInput name={"year"} value={date.year} onChange={updateState} />
            <IconButton
              component="div"
              onClick={() => setOpen(true)}
              disabled={false}
            >
              <CalendarToday color="#0000008F" />
            </IconButton>
          </Box>
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}

export default DatePicker;
