import * as React from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const initProps = {
  title: null, //required
  onClick: null, //required
  variant: null,
  disable: null,
  otherProps: null,
  style: null,
  color: null,
  label: null,
  fontVariant: "",
  fontWeight: "",
};

export default function EwButton(props = initProps) {
  var {
    title,
    onClick,
    otherProps,
    variant,
    disable,
    style,
    color,
    label,
    isLoading,
    fontVariant,
    fontWeight,
  } = props;

  if (!style) style = {};
  if (label) title = label;
  if (!title || !onClick)
    return (
      <>
        <span style={{ color: "red" }}>Button Data Missing!</span>
      </>
    );
  if (!variant) variant = "contained";
  if (variant === "text2")
    return (
      <div style={{ padding: 5 }} className="pointer" onClick={onClick}>
        <Typography variant="components_field_text1" sx={{ color: "#47294C" }}>
          {title}
        </Typography>
      </div>
    );
  return (
    <>
      <Button
        style={{ ...style, textTransform: "none" }}
        color={color}
        onClick={onClick}
        disabled={disable || isLoading}
        {...otherProps}
        variant={variant}
      >
        <Typography
          variant={fontVariant || "button_medium"}
          fontWeight={fontWeight || "400"}
        >
          {isLoading ? "Loading.." : title}
        </Typography>
      </Button>
    </>
  );
}
