import React from "react";
import Checkbox_1 from "./Checkbox_1";
import { CheckboxPropTypes } from "./types";

const reactMemo: <T>(
  c: T,
  propsAreEqual: (prev: any, curr: any) => boolean
) => T = React.memo;

export default reactMemo(Checkbox_1, (prevProps, currProps): boolean => {
  const fields = Object.keys(prevProps) as (keyof CheckboxPropTypes)[];
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log(
        "Rerendered based on: " +
          fields[i] +
          " " +
          (currProps.placeholder || currProps.helperText)
      );
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
