import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Stack, Typography, useTheme } from "@mui/material";
import { isEmpty } from "../../Library/Helpers";

const defaultProps = {
  name: "",
  label: null,
  onChange: null,
  type: null,
  onBlur: null,
  rows: null,
  value: null,
  styles: null,
  InputProps: null,
  error: null,
  disable: null,
  maxChars: null,
  regex: null,
  errorMsg: null,
  helperText: null,
  containerStyles: null,
  fontVariant: null,
  variant: null,
  placeholder: "",
  width: "",
  removeHelperText: false,
  onChangeEvent: () => {},
  preText: "", //text before input
  postText: "", //text after input
  textVariant: "", // typography variant
  inputWidth: "",
  required: false,
  reviewMode: false,
  otherProps: {},
};

function Input(props = defaultProps) {
  var {
    name,
    label,
    onChange,
    type,
    value,
    onBlur,
    rows,
    InputProps,
    variant,
    error,
    styles,
    disable,
    tooltip,
    maxChars,
    otherProps,
    fontVariant,
    id,
    group,
    regex,
    errorMsg,
    helperText,
    containerStyles,
    width,
    placeholder,
    removeHelperText,
    onChangeEvent,
    preText,
    postText,
    textVariant,
    inputWidth,
    required,
    inpType,
    reviewMode,
  } = props;

  const [Error, setError] = useState(error);

  useEffect(() => {
    setError(error);
  }, [error]);

  console.log(label, "ATOMIC_INPUT_LABEL");
  styles = { ...styles };
  if (inpType) type = inpType;
  if (!onChange) {
    onChange = () => {};
  }
  if (!onBlur) {
    onBlur = () => {};
  }
  if (!variant) {
    variant = "standard";
  }
  if (!fontVariant) {
    fontVariant = "p4";
  }
  if (type === "+number" || type === "-number") {
    if (!InputProps) InputProps = {};
    let field = type === "+number" ? "min" : "max";
    InputProps = {
      ...InputProps,
      inputProps: { [field]: 0 },
    };

    type = "number";
    //     inputProps: { min: 0 },
  }

  if (!containerStyles) {
    containerStyles = {};
  }
  if (!styles) styles = {};
  styles.width = inputWidth || "100%";
  // if (width) styles.width = width;
  // else styles.width = "100%";
  function onBlurCalled(val, type) {
    if (required && isEmpty(val)) {
      setError(true);
    } else {
      setError(false);
    }
    onBlur && onBlur(val, type);
  }

  if (!removeHelperText && !helperText) {
    if (label) {
      helperText = label;
    } else {
      helperText = "";
    }
  }

  function updateValue(e) {
    setError(null);
    if (onChangeEvent) onChangeEvent(e);
    let value = e.target.value;
    if (type === "number" && InputProps?.inputProps?.min === 0) {
      value = +value < 0 ? 0 : value;
    }
    if (type === "number" && InputProps?.inputProps?.max === 0) {
      value = +value > 0 ? 0 : value;
    }
    onChange(value, e.target.name);
  }

  const { typography } = useTheme();

  if (reviewMode)
    return (
      <>
        <Typography variant="form_h1">
          {`${label}: `}
          <Typography variant="form_text1">{value}</Typography>
        </Typography>
      </>
    );
  return (
    <Stack
      direction={"row"}
      gap={1}
      alignItems={"flex-end"}
      width={width ? width : "100%"}
    >
      {preText && (
        <Typography variant={textVariant ? textVariant : "form_text3"}>
          {preText}
        </Typography>
      )}

      <TextField
        required={required}
        sx={styles}
        multiline={rows}
        rows={rows}
        error={Error}
        {...props}
        placeholder={`${placeholder || ""}${required ? "*" : ""}`}
        helperText={
          Error && errorMsg
            ? errorMsg + "*"
            : value
            ? removeHelperText
              ? ""
              : helperText || placeholder
            : removeHelperText
            ? ""
            : " "
        }
        onBlur={(e) => onBlurCalled(e.target.value, e.target.name)}
        value={value || ""}
        type={type}
        onChange={updateValue}
        label={label}
        variant={variant}
        InputProps={{
          disabled: disable,
          ...InputProps,
        }}
        InputLabelProps={{
          sx: {
            ...typography[fontVariant],
          },
        }}
        inputProps={{
          maxLength: maxChars ? maxChars : 1000,
          style: {
            ...typography[fontVariant],
          },
        }}
        {...otherProps}
        name={name}
      />

      {postText && (
        <Typography variant={textVariant ? textVariant : "form_text3"}>
          {postText}
        </Typography>
      )}
    </Stack>
  );
}

export default React.memo(Input, (prevProps, currProps) => {
  const fields = Object.keys(prevProps);
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log(
        "Rerendered based on: " +
          fields[i] +
          " " +
          (currProps.helperText || currProps.label || currProps.placeholder)
      );
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
