import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, Typography, useTheme } from "@mui/material";
import Select from "@mui/material/Select";
import { getData } from "../../Library/Api/Services";
import { isEmpty } from "../../Library/Helpers";
import { KeyboardArrowDown } from "@mui/icons-material";

const defaultProps = {
  name: "",
  label: null,
  helperText: null,
  menuItems: null,
  onChange: null,
  value: null,
  disable: null,
  styles: null,
  featureId: null,
  required: null,
  IconComponent: null,
  removeHelperText: null,
  variant: null,
  fontVariant: null,
  mapper: {
    label: "",
    value: "",
  },
  apiResult: null,
  placeholder: null,
  storeApiResult: null,
  size: null,
  filter: null,
  getSelectedDoc: null,
  fields: null, //["Header.*","Data.Name","Data.shortName",] / "* (all values)"
  formStyles: {},
  width: 0,
  getDataProps: {},
  documentType: "ETY",
  menuHeight: "10rem",
  error: false,
  errorMsg: "",
  onBlur: () => {},
};

/*  Example
   <Dropdown
          label={"Dimesnions"}
          mapper={{
            label: "DimensionName",
            value: "TransactionID",
          }}
          featureId={DimensionFeatureId}
          value={GoalSettingsDimensions}
          apiResult={goalDimlist}
          storeApiResult={(val) => updateState(val, "goalDimlist")}
          onChange={(val) => updateState(val, "GoalSettingsDimensions")}
        />
*/

function Dropdown(props = defaultProps) {
  const [val, setValue] = useState("");
  const [Error, setError] = useState(false);
  var {
    name,
    label,
    menuItems,
    onChange,
    value,
    disable,
    styles,
    featureId,
    mapper,
    apiResult,
    storeApiResult,
    variant,
    size,
    filter,
    required,
    documentType,
    removeHelperText,
    placeholder,
    fontVariant,
    fields,
    helperText,
    formStyles,
    width,
    getDataProps,
    menuHeight,
    error,
    errorMsg,
    onBlur,
  } = props;

  console.log(label, "ATOMIC_DROPDOWN_LABEL");
  const { typography } = useTheme();
  useEffect(() => {
    setValue(value);
  }, [value]);
  useEffect(() => {}, [featureId]);
  if (!variant) variant = "standard";

  if (!featureId && menuItems && !menuItems[0]?.label && !mapper) {
    menuItems = menuItems.map((ele) => ({ label: ele }));
  }
  if (!removeHelperText && !helperText) {
    if (placeholder) {
      helperText = placeholder;
    } else {
      helperText = "";
    }
  }
  if (!placeholder) {
    placeholder = helperText;
  }
  if (!fontVariant) fontVariant = "p4";

  const [list, setList] = useState(
    menuItems || menuItems?.length === 0
      ? menuItems
      : [{ label: "Loading", value: "" }]
  );
  const [idToDocMap, setIdToDoc] = useState({});
  const [sentIdDocMap, setIdDocMap] = useState(false); //This is used as a flag to avoid re-render and send idToDocMap only once when loaded
  function filterData(item) {
    console.log("From Filter: ", item);
    if (filter) return filter(item);
    // if (getAllStatus) return true;
    // if (item.Status === "1") return true;

    return true;
  }
  // useEffect(() => {
  //   if (value && idToDocMap) {
  //     console.log("ID Doc map is: ",idToDocMap)
  //     onChange(value, idToDocMap);
  //     //setIdDocMap(true);
  //   }
  // }, []);
  useEffect(() => {
    if (featureId && !apiResult) {
      if (!fields)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fields = [`Header.${mapper.value}`, `Fields.${mapper.label}`];
      if (fields === "*") {
        fields = ["Header.*", "Fields.*"];
      }
      getData({
        featureId,
        returnData: fields,
        documentType: documentType || "ETY",
        ...getDataProps,
      })
        .then((res) => {
          if (res?.ReturnData?.length > 0) {
            let { label, labelAggregator, value } = mapper;
            let obj = idToDocMap;
            let arr = [];
            res.ReturnData.map((ele) => {
              if (ele.Fields) {
                return {
                  ...ele.Fields,
                  ...ele.Headers,
                };
              }
              return ele;
            })
              .filter(filterData)
              .forEach((ele) => {
                obj[ele[mapper.value]] = ele;
                arr.push({
                  label: labelAggregator
                    ? labelAggregator(ele)
                    : label
                    ? ele[label] || "No Label available"
                    : "No Label available",
                  value: ele[value],
                });
              });

            setIdToDoc({ ...obj });
            setList([...arr]);
            if (storeApiResult) storeApiResult(arr);
          } else {
            setList([
              {
                label: "No Data",
                value: "",
              },
            ]);
          }
        })
        .catch((e) => {
          setList([
            {
              label: "Network Error",
              value: "",
            },
          ]);
        });
    } else if (apiResult) {
      setList([...apiResult]);
    }

    if (value) {
      setValue(value);
    }
    if (
      JSON.stringify(list) !== JSON.stringify(menuItems) &&
      menuItems?.length > 0
    ) {
      setList(menuItems);
    }
  }, [menuItems, getDataProps, featureId]);
  useEffect(() => {
    if (!featureId && Array.isArray(menuItems)) {
      console.log("Menu ITem is: ", menuItems);
      const idDocMap = {};
      menuItems?.forEach(({ label, value }) => {
        idDocMap[value] = label;
      });
      setIdToDoc({ ...idDocMap });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!list || (list?.length === 0 && !featureId) || !Array.isArray(list))
    return <>List Error</>;
  function getLabel(ele) {
    if (!featureId && mapper) {
      return ele[mapper.label];
    }
    return ele.label;
  }
  function getValue(ele) {
    if (!featureId && mapper) {
      return ele[mapper.value];
    }
    if (!ele.value) return ele.label;
    return ele.value;
  }
  if (!formStyles) formStyles = {};
  if (!styles) styles = {};
  if (width) {
    styles.width = width;
    formStyles.width = width;
  }
  function onBlurCalled(val, type) {
    if (required && isEmpty(val)) {
      setError("Filed cannot be empty");
    }
    onBlur && onBlur(val, type);
  }
  return (
    <>
      <Box
        sx={{
          minWidth: width || 120,
          ...styles,
          position: "relative",
        }}
      >
        <FormControl
          sx={{ ...formStyles }}
          size={size || ""}
          fullWidth
          variant={variant}
          required={required || false}
          error={error || Error}
        >
          {!value && (
            <InputLabel
              shrink={label ? undefined : false}
              sx={{
                color: "#b8b8b8",
                ...typography[fontVariant],
                top: label ? undefined : "-16px",
                "& +.MuiInputBase-root": {
                  marginTop: label ? undefined : 0,
                },
              }}
            >
              {label || placeholder}
            </InputLabel>
          )}
          <Select
            IconComponent={KeyboardArrowDown}
            disabled={disable}
            value={
              !value || !list.find((l) => getValue(l) === value) ? "" : val
            }
            onChange={(e) => {
              console.log("selected value: ", e);
              let val = e.target.value;
              setValue(val);
              onChange(val, e.target.name, idToDocMap[val]);
            }}
            required={required}
            variant={variant}
            placeholder={placeholder}
            sx={{
              fontSize: typography[fontVariant].fontSize,
            }}
            MenuProps={{
              PaperProps: { sx: { height: menuHeight || "10rem" } },
            }}
            name={name}
            onBlur={(e) => onBlurCalled(e.target.value, e.target.name)}
          >
            <MenuItem value={""}>
              <Typography variant={fontVariant}>{"Select a Value"}</Typography>
            </MenuItem>
            {console.log("Label: ", list)}
            {list.map((ele) => (
              <MenuItem disabled={ele.disable} value={getValue(ele)}>
                <Typography variant={fontVariant}>{getLabel(ele)}</Typography>
              </MenuItem>
            ))}
          </Select>
          {(!removeHelperText || errorMsg) && (
            <FormHelperText>
              {value && list.find((l) => getValue(l) === value)
                ? helperText
                : (error || Error) && errorMsg
                ? errorMsg
                : " "}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </>
  );
}

export default React.memo(Dropdown, (prevProps, currProps) => {
  const fields = Object.keys(prevProps);
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log(
        "Rerendered based on: " +
          fields[i] +
          " " +
          (currProps.label || currProps.placeholder || currProps.helperText)
      );
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
