import React, { FocusEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, Typography, useTheme } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { isEmpty } from "../../../Library/Helpers";
import { KeyboardArrowDown } from "@mui/icons-material";
import { DropdownMenuItem, DropdownPropTypes, isHeaderItem } from "./types";
import ErrorTooltip from "../../ErrorTooltip";
import _ from "lodash";

const Dropdown: React.FC<DropdownPropTypes> = (
  props: DropdownPropTypes
): JSX.Element => {
  const {
    name,
    menuItems = [],
    onChange = (
      value: string | number,
      type: string | undefined,
      label: string
    ) => {},
    onBlur = (
      value: string | number,
      type: string | undefined,
      label: string
    ) => {},
    value,
    disable = false,
    styles = {},
    popperArrow = KeyboardArrowDown,
    removeHelperText = false,
    variant = "standard",
    fontVariant = "form_text2",
    size = "medium",
    formStyles = {},
    width = "7rem",
    menuPaperHeight = "10rem",
    error = "",
    showHelperText = false,
    helperTextFontVariant = "helper_text_small",
    OptionComponent,
    ValueComponent,
    selectOnly = false,
    attributes,
  } = props;

  let { visibility = true, helperText, placeholder, required = false } = props;

  /**
   * This condition assigns all necessary attributes to the input
   */
  if (attributes) {
    const { Input, Visibility, Label } = attributes;
    required = Input === "M";
    visibility = Visibility === "D";
    placeholder = _.startCase(Label).trim();
  }

  const { typography } = useTheme();
  const [items, setItems] = useState<
    DropdownMenuItem[] | { [key: string]: DropdownMenuItem[] }
  >([]);
  const [selectedValue, setSelectedValue] = useState<string | number | null>(
    value || null
  );
  const [labelMap, setLabelMap] = useState<{ [key: string]: string }>({});
  const [Error, setError] = useState<string>("");

  useEffect(() => {
    if (!selectOnly) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    if (
      JSON.stringify(items) !== JSON.stringify(menuItems) &&
      (Array.isArray(menuItems)
        ? menuItems.length > 0
        : Object.values(menuItems).flat().length > 0)
    ) {
      let convertedMenuItems:
        | DropdownMenuItem[]
        | { [key: string]: DropdownMenuItem[] } = [];

      if (Array.isArray(menuItems)) {
        if (menuItems.length > 0 && typeof menuItems[0] === "string") {
          convertedMenuItems = (menuItems as string[]).map((item) => ({
            label: item,
            value: item,
            disable: false,
          }));
        } else {
          convertedMenuItems = menuItems as DropdownMenuItem[];
        }
      } else {
        const flatArray = Object.values(menuItems).flat();
        if (flatArray.length > 0 && typeof flatArray[0] === "string") {
          convertedMenuItems = Object.entries(
            menuItems as { [key: string]: string[] }
          ).reduce(
            (total: { [key: string]: DropdownMenuItem[] }, [key, items]) => {
              total[key] = items.map((i) => ({
                label: i,
                value: i,
                disable: false,
              }));
              return total;
            },
            {}
          );
        } else {
          convertedMenuItems = menuItems as {
            [key: string]: DropdownMenuItem[];
          };
        }
      }

      setLabelMap(
        (Array.isArray(convertedMenuItems)
          ? convertedMenuItems
          : Object.values(convertedMenuItems).flat()
        ).reduce<{ [key: string]: string }>((total, current) => {
          if (current.value) {
            total[current.value] = current.label;
          }
          return total;
        }, {})
      );
      setItems(convertedMenuItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  const getValue = (
    value: string | number | null | undefined
  ): string | number => {
    if (
      !value ||
      (Array.isArray(items)
        ? !items.find((i) => i.value === value)
        : !Object.values(items)
            .flat()
            .find((i) => i.value === value))
    )
      return "";
    return value;
  };

  const onBlurEvent = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value,
      name = event.target.name;
    if (required && isEmpty(value)) {
      setError("This field is required");
    }
    onBlur(value, name, labelMap[value]);
  };

  const onChangeEvent = (event: SelectChangeEvent<string | number>) => {
    const value = event.target.value,
      name = event.target.name;
    if (value && Error) {
      setError("");
    }
    if (!selectOnly) {
      setSelectedValue(value);
    }
    onChange(value, name, labelMap[value]);
  };

  return !visibility ? (
    <></>
  ) : (
    <ErrorTooltip title={Error} placement={"top-start"}>
      <Box
        sx={{
          ...styles,
          position: "relative",
        }}
      >
        <FormControl
          sx={{ ...formStyles, width }}
          size={size}
          fullWidth
          variant={variant}
          required={required}
          error={Error ? true : false}
        >
          {!selectedValue && (
            <InputLabel
              shrink={false}
              sx={{
                color: "#b8b8b8",
                top: "-16px",
                "& +.MuiInputBase-root": {
                  marginTop: 0,
                },
                ...(typography[
                  fontVariant as keyof typeof typography
                ] as InputLabelProps["sx"]),
              }}
            >
              {placeholder}
            </InputLabel>
          )}
          <Select
            IconComponent={popperArrow}
            disabled={disable}
            value={getValue(selectedValue)}
            onChange={onChangeEvent}
            required={required}
            variant={variant}
            placeholder={placeholder}
            sx={{
              fontSize: (
                typography[
                  fontVariant as keyof typeof typography
                ] as React.CSSProperties
              ).fontSize,
            }}
            MenuProps={{
              MenuListProps: {
                sx: {
                  height: menuPaperHeight,
                  paddingLeft: "0rem",
                  paddingRight: "0.2rem",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "5px",
                    height: "3px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#69206F",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "white",
                    borderRadius: "10px",
                  },
                },
              },
              PaperProps: {
                sx: { padding: "0.5rem" },
              },
              variant: "menu",
            }}
            name={name}
            onBlur={onBlurEvent}
            renderValue={
              ValueComponent
                ? () => {
                    let label: string = "",
                      value: string | number = "";
                    const menuItem = (
                      Array.isArray(menuItems)
                        ? menuItems
                        : Object.values(menuItems).flat()
                    ).find((i) => {
                      return typeof i === "string"
                        ? i === selectedValue
                        : i.value === selectedValue;
                    });
                    if (menuItem) {
                      label =
                        typeof menuItem === "string"
                          ? menuItem
                          : menuItem.label;
                      value =
                        typeof menuItem === "string"
                          ? menuItem
                          : menuItem.value;
                    }
                    return <ValueComponent label={label} value={value} />;
                  }
                : undefined
            }
          >
            <MenuItem value={""}>
              <Typography variant={fontVariant}>{"Select a Value"}</Typography>
            </MenuItem>
            {Array.isArray(items)
              ? items.map((item) => {
                  return (
                    <MenuItem disabled={item.disable} value={item.value}>
                      {OptionComponent ? (
                        <OptionComponent menuItem={item} />
                      ) : (
                        <Typography variant={fontVariant}>
                          {item.label}
                        </Typography>
                      )}
                    </MenuItem>
                  );
                })
              : Object.entries(items)
                  .reduce((total: any, [key, value]) => {
                    total.push({ header: key });
                    total = total.concat(value);
                    return total;
                  }, [])
                  .map(
                    (
                      item: { header: string } | DropdownMenuItem,
                      index: number
                    ) => {
                      return isHeaderItem(item) ? (
                        <Typography variant="form_text3">
                          {item.header}
                        </Typography>
                      ) : (
                        <MenuItem disabled={item.disable} value={item.value}>
                          {OptionComponent ? (
                            <OptionComponent menuItem={item} />
                          ) : (
                            <Typography variant={fontVariant}>
                              {item.label}
                            </Typography>
                          )}
                        </MenuItem>
                      );
                    }
                  )}
          </Select>
          {(!removeHelperText || showHelperText) && (
            <FormHelperText sx={{ height: "19.7917px", marginTop: "3px" }}>
              <Typography
                variant={helperTextFontVariant}
                sx={{ color: "#00000099" }}
                fontWeight={400}
              >
                {(selectedValue && getValue(selectedValue)) || showHelperText
                  ? helperText || placeholder
                  : " "}
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </ErrorTooltip>
  );
};

export default Dropdown;
