import { Box, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import useStateUpdater from "../../Library/Hooks/useStateUpdater";
import SmallDropdown from "../Dropdown/SmallDropdown";

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minutes = ["00", "15", "30", "45"];

function PeriodSwitch({ value, onChange }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        minWidth: "3.75rem",
        minHeight: "1.5rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          padding: "0.2rem 0.3rem",
          border: `1.5px solid ${
            value === "AM" ? theme.palette.primary.main : "#DDDDDD"
          }`,
          borderRight: value === "AM" || !value ? undefined : 0,
          cursor: "pointer",
          borderTopLeftRadius: "0.281875rem",
          borderBottomLeftRadius: "0.281875rem",
        }}
        onClick={() => {
          onChange("AM");
        }}
      >
        <Typography variant="form_text2">AM</Typography>
      </Box>
      <Box
        sx={{
          padding: "0.2rem 0.3rem",
          paddingLeft: "0.25rem",
          cursor: "pointer",
          border: `1.5px solid ${
            value === "PM" ? theme.palette.primary.main : "#DDDDDD"
          }`,
          borderLeft: value === "PM" ? undefined : 0,
          borderTopRightRadius: "0.281875rem",
          borderBottomRightRadius: "0.281875rem",
        }}
        onClick={() => {
          onChange("PM");
        }}
      >
        <Typography variant="form_text2">PM</Typography>
      </Box>
    </Box>
  );
}

function millisecondsToTime(milliseconds) {
  let date = new Date(milliseconds);

  let hours = date.getHours();
  let minutes = date.getMinutes();

  let amOrPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? "0" + minutes : minutes;

  return {
    hours,
    minutes,
    period: amOrPm,
  };
}

function timeToMilliseconds(hours, minutes, amOrPm) {
  if (amOrPm.toUpperCase() === "PM" && hours < 12) {
    hours += 12;
  } else if (amOrPm.toUpperCase() === "AM" && hours === 12) {
    hours = 0;
  }

  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date.getTime();
}

function TimePicker({
  name,
  label,
  labelVariant,
  value,
  onChange,
  showLabel = true,
}) {
  if (!labelVariant) labelVariant = "form_h1";
  if (!showLabel) label = "";

  const [time, updateTime, updateStore] = useStateUpdater({
    hours: null,
    minutes: null,
    period: null,
  });

  const updateState = useCallback((value, type) => {
    updateTime(value, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value && typeof value === "number") {
      updateStore(millisecondsToTime(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time.hours && time.minutes && time.period) {
      onChange(
        timeToMilliseconds(time.hours, time.minutes, time.period),
        name,
        time
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
      {label && <Typography variant={labelVariant}>{label}</Typography>}
      <SmallDropdown
        name={"hours"}
        value={time.hours}
        onChange={updateState}
        menuItems={hours}
        placeholder={"HH"}
        width={"3.3rem"}
      />
      <Typography variant="form_text2">:</Typography>
      <SmallDropdown
        name={"minutes"}
        value={time.minutes}
        onChange={updateState}
        menuItems={minutes}
        placeholder={"MM"}
        width={"4rem"}
      />
      <Typography variant="form_text2"> </Typography>
      <PeriodSwitch
        value={time.period}
        onChange={(value) => {
          updateTime(value, "period");
        }}
      />
    </Box>
  );
}

export default TimePicker;
