import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
const defaultProps = {
  name: "",
  menuItems: null,
  label: null,
  onChange: null,
  value: null,
  flex: null,
  flexGap: 0,
  fontVariant: null,
  disable: null,
  labelVariant: "",
  required: false,
  error: false,
  errorMsg: "",
};

const row = {
  display: "flex",
  flexDirection: "row",
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  backgroundColor: theme.palette.primary["100"],
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: `radial-gradient(${theme.palette.secondary.main},${theme.palette.secondary.main} 28%,transparent 32%)`,
    content: '""',
  },
}));

export function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

function EwRadio(props = defaultProps) {
  var {
    name,
    menuItems,
    label,
    onChange,
    value,
    flex,
    flexGap,
    containerStyles,
    disable,
    fontVariant,
    labelVariant,
    required,
    error,
    errorMsg,
  } = props;

  if (menuItems && typeof menuItems[0] === "string") {
    menuItems = menuItems.map((ele) => ({ value: ele, label: ele }));
  }
  var styles = {};
  if (flex === 1) styles = row;
  if (containerStyles) styles = { ...styles, ...containerStyles };

  const [radioValue, setRadioValue] = useState(value || null);

  useEffect(() => {
    setRadioValue(value);
  }, [value]);

  return (
    <FormControl component="fieldset" disabled={disable}>
      {/* <FormLabel component="legend">{label}</FormLabel> */}
      {label && (
        <Typography
          variant={labelVariant || "form_text1"}
          sx={{ display: "flex", alignItems: "flex-start", gap: "0.5rem" }}
        >
          {label}{" "}
          {error && (
            <Typography
              variant={"helper_text"}
              color={"error"}
              sx={{ alignSelf: "center" }}
              fontWeight={600}
            >
              ({errorMsg || "This is required"})
            </Typography>
          )}
          {required && (
            <Typography variant={labelVariant || "form_text1"} color={"error"}>
              *
            </Typography>
          )}
        </Typography>
      )}
      {console.log("value: ", value)}
      <RadioGroup
        style={{ ...styles, gap: flexGap }}
        value={radioValue}
        onChange={(e) => {
          onChange(e.target.value, e.target.name);
          setRadioValue(e.target.value);
        }}
        name={name}
      >
        {menuItems.map((ele) => {
          const { label, value, LabelComponent } = ele;
          return (
            <FormControlLabel
              value={value ? value : label}
              control={<BpRadio />}
              label={
                <>
                  {LabelComponent ? (
                    <LabelComponent />
                  ) : (
                    <Typography variant={fontVariant || "helper_text"}>
                      {label}
                    </Typography>
                  )}
                </>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default React.memo(EwRadio, (prevProps, currProps) => {
  const fields = Object.keys(prevProps);
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log(
        "Rerendered based on: " +
          fields[i] +
          " " +
          (currProps.helperText || currProps.label || currProps.placeholder)
      );
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
