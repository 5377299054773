import React, { useEffect, useState } from "react";
import { AutoCompletePropTypes } from "./types";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Box,
  FilterOptionsState,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import ErrorTooltip from "../../ErrorTooltip";

const styleProps = {
  "& .MuiInput-root": {
    paddingBottom: 0,
  },
  "& .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input": {
    padding: "4px 0 5px",
  },
};

/**
 * Autocomplete created by using MUI Autocomplete. This can be used for typeahead wherever needed.
 * Can be used in the following ways
 * 1. Single select,
 * 2. Multiple select,
 * 3. Option can be either text or a custom option component which can be passed.
 *
 * @param {AutoCompletePropTypes} props
 * @returns Autocomplete component
 */
const AutoComplete_1: React.FC<AutoCompletePropTypes> = (
  props: AutoCompletePropTypes
) => {
  const { typography, palette } = useTheme();
  let {
    name,
    placeholder = "",
    mapper,
    onChange = (value: any, type?: string) => {},
    variant = "standard",
    multiple,
    width = "30rem",
    value,
    OptionComponent,
    disableTags = false,
    getOptionLabel = (data: any) => {
      return getFieldValue(data, mapper.label) || "";
    },
    reviewMode,
    removePopperArrow,
    fontVariant,
    size = "medium",
    helperText = placeholder,
    options = [],
    loading = false,
    required = false,
    error = "",
    menuHeight = "15rem",
    removeHelperText = false,
    attributes,
    visibility = true,
  } = props;

  /**
   * This condition assigns all necessary attributes to the input
   */
  if (attributes) {
    const { Input, Visibility, Label } = attributes;
    required = Input === "M";
    visibility = Visibility === "D";
    placeholder = Label || "";
  }

  const optionValueKey = mapper.value;

  const [inputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState<any | Array<any>>(
    multiple ? [] : null
  );
  const [errored, setErrored] = useState<string>(error);

  /**
   * @param {any} data An object present in the options array
   * @param {string} field A field which can have '.' in between which can even point to nested fields.
   * @returns Value of the field present in the data, if not found, it will return an empty string.
   */
  const getFieldValue = (data: any, field: string): string => {
    const keys = field.split(".");
    let doc = data;
    keys.forEach((key) => {
      doc = doc[key];
    });
    return doc || "";
  };

  useEffect(() => {
    if (value) {
      if (multiple) {
        /**
         * multiple: value array can have array of string using which we can find
         * the respective documents and store them in the localstate
         */
        const selectedValues: Array<any> = [];
        (value as Array<string | number>).forEach((v: string | number) => {
          const element = options.find(
            (d) => getFieldValue(d, optionValueKey) === v
          );
          if (element) {
            selectedValues.push(element);
          }
        });
        setSelected(selectedValues);
      } else {
        /**
         * single: value can have string using which we can find
         * the respective document and store it in the localstate
         */
        const element = options.find(
          (d) => getFieldValue(d, optionValueKey) === value
        );
        setSelected(element || null);
      }
    } else {
      /**
       * No value is seleced which means to make localstate empty
       */
      setSelected(multiple ? [] : null);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [options, multiple, value]);

  /**
   * @returns Review component used when the reviewMode is enabled
   */
  const ReviewMode: React.FC<any> = () => (
    <Box>
      {OptionComponent ? (
        <OptionComponent data={selected} inputValue="" />
      ) : (
        <Typography variant={fontVariant}>
          {getOptionLabel(selected)}
        </Typography>
      )}
    </Box>
  );

  /**
   * @param {any} option
   * @param {any} value
   * @returns A boolean value that represents whether a selected value is equal to a option or not.
   */
  const isOptionEqualTo = (option: any, value: any) => {
    return (
      getFieldValue(option, optionValueKey) ===
      getFieldValue(value, optionValueKey)
    );
  };

  /**
   * @param {React.SyntheticEvent} event onChange event
   * @param {any} newValue The newValue that is stored in the localstate and sent to the parent component
   * @param {AutocompleteChangeReason} action The type of change that happened to the Autocomplete component
   */
  const onChangeEvent = (
    event: React.SyntheticEvent,
    newValue: any,
    action: AutocompleteChangeReason
  ) => {
    if (multiple) {
      if (action === "selectOption" || action === "removeOption") {
        onChange(
          newValue.map((v: any) => getFieldValue(v, optionValueKey)),
          name,
          newValue
        );
      } else if (action === "clear") {
        onChange([], name, newValue);
      }
    } else {
      if (action === "selectOption") {
        onChange(getFieldValue(newValue, optionValueKey), name, newValue);
      } else {
        onChange("", name, newValue);
      }
      setInputFocused(false);
    }
    setSelected(newValue);
  };

  /**
   * This is used to store the input that the user typed in the localstate
   * so that it can be passed to the Custom OptionComponent if used
   * @param {React.SyntheticEvent} event
   * @param {string} newInputValue
   */
  const onInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  /**
   * @param {Array<any>} options
   * @param {FilterOptionsState<any>} state
   * @returns Returns the filtered options based on the inputValue that is entered in the Autocomplete input
   */
  const filterOptions = (
    options: Array<any>,
    state: FilterOptionsState<any>
  ) => {
    return options.filter((option) =>
      getOptionLabel(option)
        .toLowerCase()
        .includes(state.inputValue.toLowerCase())
    );
  };

  /**
   * @param {any} props The MenuItem props related to the option.
   * @param {any} doc The Document of the respective option.
   * @returns The custom option component
   */
  const renderOption = (props: any, doc: any) => (
    <MenuItem
      {...props}
      sx={{
        ...props.sx,
        ...(typography[
          fontVariant as keyof typeof typography
        ] as React.CSSProperties),
      }}
    >
      {OptionComponent ? (
        <OptionComponent data={doc} inputValue={inputValue} />
      ) : (
        <Typography variant={fontVariant}>{getOptionLabel(doc)}</Typography>
      )}
    </MenuItem>
  );

  const InputHelperText: string = multiple
    ? (value as Array<string | number>).length > 0
      ? helperText
      : " "
    : value
    ? helperText
    : " ";

  /**
   * @param {AutocompleteRenderInputParams} params
   * @returns The Input Textfield used in the Autocomplete with additional configuration
   */
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      focused={inputFocused}
      onFocus={() => setInputFocused(true)}
      onBlur={() => {
        if (required) {
          if (multiple && Array.isArray(value)) {
            if (value.length === 0) {
              setErrored("This field is required");
            } else {
              setErrored("");
            }
          } else {
            if (!value) {
              setErrored("This field is required");
            } else {
              setErrored("");
            }
          }
        }
        setInputFocused(false);
      }}
      placeholder={required ? `${placeholder} *` : placeholder}
      variant={variant}
      sx={{
        width: width,
        "& input::placeholder": {
          color: errored ? palette.error.main : undefined,
          opacity: errored ? 1 : undefined,
        },
        "& .MuiInput-underline:before": {
          borderBottom: errored ? `2px solid ${palette.error.main}` : undefined,
        },
      }}
      InputProps={{
        ...params.InputProps,
        sx: {
          ...(typography[
            fontVariant as keyof typeof typography
          ] as React.CSSProperties),
        },
      }}
      InputLabelProps={{
        ...params.InputLabelProps,
        shrink: false,
        sx: {
          ...(typography[
            fontVariant as keyof typeof typography
          ] as React.CSSProperties),
          top: "-12px",
          "& +.MuiInputBase-root": {
            marginTop: 0,
          },
        },
        required: true,
      }}
      FormHelperTextProps={
        removeHelperText ? undefined : { sx: { marginTop: "3px" } }
      }
      helperText={removeHelperText ? "" : InputHelperText}
      required
    />
  );

  return !visibility ? (
    <></>
  ) : reviewMode && !multiple ? (
    <ReviewMode />
  ) : (
    <ErrorTooltip title={errored}>
      <Autocomplete
        id="autocomplete"
        sx={{
          width: width,
          ...styleProps,
        }}
        size={size}
        loading={loading}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualTo}
        value={selected}
        onChange={onChangeEvent}
        inputValue={disableTags ? "" : inputValue}
        onInputChange={onInputChange}
        filterOptions={filterOptions}
        multiple={multiple}
        open={inputFocused ? true : false}
        renderOption={renderOption}
        renderInput={renderInput}
        renderTags={disableTags ? () => null : undefined}
        popupIcon={!removePopperArrow ? <KeyboardArrowDown /> : null}
        ListboxProps={{
          className: "droplist-scroll",
          style: { height: menuHeight },
        }}
      />
    </ErrorTooltip>
  );
};

/** TODO: This needs to be memoized */
export default AutoComplete_1;
