import React, { useMemo } from "react";
import { SwitchPropTypes } from "./types";
import {
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import ErrorTooltip from "../../ErrorTooltip";

const Switch_1: React.FC<SwitchPropTypes> = (props: SwitchPropTypes) => {
  const {
    name,
    onChange = () => {},
    value,
    disable = false,
    error = "",
    fontVariant = "form_h1",
    size = "medium",
    type,
    sx,
    attributes,
  } = props;

  let { label, required = false, visibility = true } = props;

  /**
   * This condition assigns all necessary attributes to the input
   */
  if (attributes) {
    const { Input, Visibility } = attributes;
    required = Input === "M";
    visibility = Visibility === "D";
    // label = Label;
  }

  const switched = useMemo(() => {
    if (type !== undefined) {
      return value === type[0];
    } else {
      return value ? true : false;
    }
  }, [type, value]);

  const Label = () => (
    <Typography
      variant={fontVariant}
      color={error ? "error" : undefined}
      sx={{ display: "flex", gap: "0.5rem" }}
    >
      {label}
      {required ? " *" : ""}
    </Typography>
  );

  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const switched = e.target.checked;
    if (type !== undefined) {
      const trueValue = type[0];
      const falseValue = type[1];
      onChange(switched ? trueValue : falseValue, e.target.name);
    } else {
      onChange(switched, e.target.name);
    }
  };

  return !visibility ? (
    <></>
  ) : (
    <ErrorTooltip title={error} placement={"top-start"}>
      <FormControl component={"switch"}>
        <FormControlLabel
          control={
            <Switch
              size={size || "medium"}
              checked={switched}
              onChange={onChangeEvent}
              disabled={disable}
              name={name}
              sx={{ ...sx }}
            />
          }
          label={<Label />}
          sx={{ marginRight: 0 }}
        />
      </FormControl>
    </ErrorTooltip>
  );
};

export default Switch_1;
