import Input from "./Input/Input_3";
import Button from "./Button/Button";
import Checkboxes from "./Checkbox/Checkboxes";
import Dropdown from "./Dropdown/Dropdown_3";
import Radio from "./Radio/Radio_3";
import EwSwitch from "./Switch/EwSwitch";
import UtilsDropdown from "./Dropdown/UtilsDropdown";
import Text from "./Text";
import NumberInput from "./Input/NumberInput";
import DatePicker from "./DatePicker";
import AutoComplete from "./AutoComplete/AutoComplete";
import FeatureAutocomplete from "./AutoComplete/FeatureAutocomplete";
import TimePicker from "./TimePicker/TimePicker";

const Atomic = {
  Input,
  Button,
  Checkboxes,
  Dropdown,
  Radio,
  EwSwitch,
  UtilsDropdown,
  Text,
  NumberInput,
  DatePicker,
  TimePicker,
  AutoComplete,
  FeatureAutocomplete,
};

export default Atomic;
