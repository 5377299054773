import React, { useEffect, useState } from "react";
import { getData } from "../../Library/Api/Services";
import { Autocomplete, Box, TextField } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import "./index.css";

const initProps = {
  name: "",
  label: "",
  value: "",
  multiple: false,
  onChange: () => {},
  width: null,
  variant: "",
  documentType: "",
  featureId: "",
  getDataProps: {
    returnData: ["Header.*", "Fields.*"],
    dataMatch: {},
  },
  OptionComponent: ({ data, inputValue }) => <></>,
  reviewMode: false,
  getOptionLabel: () => {},
  renderTags: () => {},
  optionKeyField: "",
  optionLabelField: "",
  optionLabelAggregator: () => {},
  removeArrow: false,
};

function FeatureAutocomplete(props = initProps) {
  let {
    onChange,
    variant,
    multiple,
    width,
    value,
    documentType,
    featureId,
    getDataProps,
    OptionComponent,
    getOptionLabel,
    renderTags,
    reviewMode,
    optionKeyField,
    optionLabelField,
    optionLabelAggregator,
    removeArrow,
  } = props;

  if (!documentType) documentType = "ETY";
  if (!getDataProps) getDataProps = {};
  if (!getOptionLabel) getOptionLabel = () => "";
  if (!renderTags) renderTags = () => null;

  const optionKey =
    optionKeyField || documentType === "ETY"
      ? "EntityID"
      : documentType === "TRF"
      ? "FeatureVariantID"
      : "DocumentID";

  const [inputFocused, setInputFocused] = useState(false);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (featureId) {
      getData({
        documentType: documentType,
        featureId: featureId,
        returnData: ["Header.*", "Fields.*"],
        ...getDataProps,
      }).then((res) => {
        if (res.message === "No Data Found") {
          setData(null);
        } else {
          if (res && Array.isArray(res.ReturnData))
            setData(res.ReturnData.length > 0 ? res.ReturnData : null);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return reviewMode ? (
    <OptionComponent />
  ) : (
    <Autocomplete
      id="feature-autocomplete"
      options={data}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) =>
        option[optionKey] === value[optionKey]
      }
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(options, state) => {
        return options.filter((option) =>
          optionLabelAggregator(option)
            .toLowerCase()
            .includes(state.inputValue.toLowerCase())
        );
      }}
      multiple={multiple}
      open={inputFocused ? true : false}
      renderTags={renderTags}
      renderOption={(props, doc) => (
        <Box {...props}>
          <OptionComponent data={doc} inputValue={inputValue} />
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          focused={inputFocused}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          {...params}
          placeholder="Search"
          variant={variant || "outlined"}
          style={{ width: width || 700 }}
        />
      )}
      popupIcon={!removeArrow ? <KeyboardArrowDown /> : null}
      ListboxProps={{ className: "droplist-scroll" }}
    />
  );
}

export default FeatureAutocomplete;
