import { KeyboardArrowDown } from "@mui/icons-material";
import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";

export const SmallInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "0.25rem",
    border: "0",
    fontSize: "0.75rem",
    padding: "2px 0px 2px 10px",
    backgroundColor: "#F4F4F4",
    height: "24px",
  },
  "& .MuiSelect-select .MuiInputBase-input": {
    padding: 0,
  },
}));

function SmallDropdown({
  name,
  value,
  onChange,
  menuItems,
  width,
  placeholder,
}) {
  return (
    <FormControl
      sx={{
        margin: 0,
        "& .MuiInputLabel-root": {
          top: "-16px",
          left: "-5px",
          color: "#9b9b9b",
        },
      }}
    >
      <InputLabel
        shrink={value ? undefined : false}
        sx={{
          "& +.MuiInputBase-root": {
            marginTop: 0,
          },
        }}
      >
        {!value && <Typography variant="form_text2">{placeholder}</Typography>}
      </InputLabel>
      <Select
        value={value || ""}
        onChange={(e) => {
          const value = e.target.value;
          const label = menuItems.find((m) => m.value === value)?.label;
          onChange(value, e.target.name, label);
        }}
        name={name}
        IconComponent={(props) => {
          return (
            <KeyboardArrowDown
              {...props}
              fontSize="small"
              sx={{ padding: 0, fontSize: "1rem" }}
            />
          );
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              padding: "0 0.5rem",
              maxHeight: "4.375rem",
              borderRadius: 0,
              marginTop: "0.5rem",
            },
            className: "scroll",
          },
        }}
        size="small"
        input={<SmallInput sx={{ margin: 0 }} />}
        inputProps={{ style: { backgroundColor: "red" } }}
        sx={{ padding: 0, minWidth: "2.625rem", width: width }}
      >
        {menuItems.map((item, index) => {
          return typeof item !== "object" ? (
            <MenuItem
              key={index}
              value={item}
              sx={{ padding: 0, paddingLeft: "0.5rem" }}
            >
              <Typography variant="form_text2">{item}</Typography>
            </MenuItem>
          ) : (
            <MenuItem
              key={index}
              value={item.value || 0}
              sx={{ padding: 0, paddingLeft: "0.5rem" }}
            >
              <Typography variant="form_text2">{item.label || ""}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default SmallDropdown;
