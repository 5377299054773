import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { isEmpty } from "../../../Library/Helpers";
import { InputPropTypes } from "./types";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import ErrorTooltip from "../../ErrorTooltip";
import _ from "lodash";

const Input: React.FC<InputPropTypes> = (props: InputPropTypes) => {
  const { typography, palette } = useTheme();
  const {
    name,
    onChange = (value: number | string, type: string | undefined): void => {},
    value,
    onBlur = (value: number | string, type: string | undefined): void => {},
    rows,
    InputProps,
    variant = "standard",
    error = "",
    styles = {},
    disable,
    maxChars = 1000,
    multiline = false,
    fontVariant = "form_text2",
    helperTextFontVariant = "helper_text_small",
    width = "100%",
    removeHelperText = false,
    reviewMode,
    otherProps = {},
    removeIcons = true,
    showHelperText = false,
    attributes,
    inputProps = {},
    onKeyDown = () => {},
    min,
    max,
    textAlign,
  } = props;

  let {
    visibility = true,
    type = "text",
    helperText,
    placeholder,
    required = false,
  } = props;

  /**
   * This condition assigns all necessary attributes to the input
   */
  if (attributes) {
    const { Input, Visibility, DataType, Label } = attributes;
    required = Input === "M";
    visibility = Visibility === "D";
    type = ["TS", "IN", "DE"].includes(DataType || "") ? "number" : "text";
    placeholder = _.startCase(Label).trim();
  }

  const [Error, setError] = useState<string>(error);

  useEffect(() => {
    setError(error);
  }, [error]);

  function onBlurCalled(val: number | string, type: string): void {
    if (required && isEmpty(val)) {
      setError("This field is required");
    } else {
      setError("");
    }
    onBlur(val, type);
  }

  function updateValue(e: React.ChangeEvent<HTMLInputElement>): void {
    setError("");
    if (type === "number") {
      const num = +e.target.value;
      console.log("Num is: ", num, " ", min, " ", max);
      if (min !== undefined && num < min) return;
      if (max !== undefined && num > max) return;
      onChange(num, e.target.name);
    } else {
      onChange(e.target.value, e.target.name);
    }
  }

  const onKeyDownWrapper = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (type === "number") {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
        e.preventDefault();
      }
    }
    onKeyDown(e);
  };

  if (reviewMode)
    return (
      <>
        <Typography variant="form_h1">
          {`${placeholder}: `}
          <Typography variant="form_text1">{value}</Typography>
        </Typography>
      </>
    );

  return !visibility ? (
    <></>
  ) : (
    <ErrorTooltip title={Error} placement={"top-start"}>
      <Box
        sx={{
          boxSizing: "border-box",
          position: "relative",
          width: width,
        }}
      >
        <TextField
          onKeyDown={onKeyDownWrapper}
          required={required}
          sx={{
            ...styles,
            width: width,
            "& input::placeholder": {
              color: Error ? palette.error.main : undefined,
              opacity: Error ? 1 : undefined,
            },
          }}
          multiline={multiline}
          rows={rows}
          error={Error ? true : false}
          placeholder={`${placeholder || ""}${required ? " *" : ""}`}
          helperText={
            !removeHelperText || showHelperText ? (
              <FormHelperText sx={{ height: "19.7917px", marginTop: 0 }}>
                <Typography
                  variant={helperTextFontVariant}
                  fontWeight={400}
                  color={Error ? "error" : undefined}
                >
                  {value || showHelperText ? helperText || placeholder : " "}
                </Typography>
              </FormHelperText>
            ) : undefined
          }
          onBlur={(e) => onBlurCalled(e.target.value, e.target.name)}
          value={value || ""}
          type={type}
          onChange={updateValue}
          variant={variant}
          InputProps={{
            disabled: disable,
            ...InputProps,
          }}
          InputLabelProps={{
            sx: {
              ...(typography[
                fontVariant as keyof typeof typography
              ] as React.CSSProperties),
            },
          }}
          inputProps={{
            maxLength: maxChars,
            style: {
              ...(typography[
                fontVariant as keyof typeof typography
              ] as React.CSSProperties),
              textAlign: textAlign || "start",
            },

            ...inputProps,
          }}
          name={name}
          {...otherProps}
          title={""} // This is required to disable the default HTML tooltip
        />
        {!removeIcons && type === "number" && (
          <Stack sx={{ position: "absolute", right: "-20px", top: "-3px" }}>
            <ArrowDropUp
              fontSize="small"
              sx={{
                marginTop: "2px",
                color: "#0000008A",
                cursor: "pointer",
              }}
              onClick={() => {
                onChange(((value as number) || 0) + 1, name);
              }}
            />
            <ArrowDropDown
              fontSize="small"
              sx={{ marginTop: "-5px", color: "#0000008A", cursor: "pointer" }}
              onClick={() => {
                onChange(((value as number) || 0) - 1, name);
              }}
            />
          </Stack>
        )}
      </Box>
    </ErrorTooltip>
  );
};

export default Input;
