import { createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { CSSProperties } from "@mui/styles";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1Bold: true;
    link_large: true;
    link_medium: true;
    link_small: true;
    small_text1: true;
    small_text2: true;
    small_text3: true;
    masthead_menu: true;
    card_label1: true;
    card_label2: true;
    card_label3: true;
    p1: true;
    p2: true;
    p3: true;
    p4: true;
    p5: true;
    form_h1: true;
    form_text1: true;
    form_text2: true;
    form_text3: true;
    naviagtion_selected: true;
    navigation_regular: true;
    date_picker_current_month: true;
    bottom_navigation_active_label: true;
    list_subheader: true;
    table_header: true;
    tooltip_label: true;
    input_text: true;
    helper_text: true;
    helper_text_small: true;
    input_label: true;
    field_text1: true;
    avatar_initials: true;
    chip_label: true;
    xs_label: true;
    menu_item_dense: true;
    menu_item: true;
    button_small: true;
    button_medium: true;
    button_large: true;
    alert_description: true;
    alert_title: true;
    role_card_name: true;
    role_card_info: true;
    allcaps: true;
  }
}

export interface ExtendedTypographyOptions extends TypographyOptions {
  link_large: CSSProperties;
  link_medium: CSSProperties;
  link_small: CSSProperties;
  small_text1: CSSProperties;
  small_text2: CSSProperties;
  small_text3: CSSProperties;
  masthead_menu: CSSProperties;
  card_label1: CSSProperties;
  card_label2: CSSProperties;
  card_label3: CSSProperties;
  p1: CSSProperties;
  p2: CSSProperties;
  p3: CSSProperties;
  p4: CSSProperties;
  p5: CSSProperties;
  form_h1: CSSProperties;
  form_text1: CSSProperties;
  form_text2: CSSProperties;
  form_text3: CSSProperties;
  naviagtion_selected: CSSProperties;
  navigation_regular: CSSProperties;
  date_picker_current_month: CSSProperties;
  bottom_navigation_active_label: CSSProperties;
  list_subheader: CSSProperties;
  table_header: CSSProperties;
  tooltip_label: CSSProperties;
  input_text: CSSProperties;
  helper_text: CSSProperties;
  helper_text_small: CSSProperties;
  input_label: CSSProperties;
  field_text1: CSSProperties;
  avatar_initials: CSSProperties;
  chip_label: CSSProperties;
  xs_label: CSSProperties;
  menu_item_dense: CSSProperties;
  menu_item: CSSProperties;
  button_small: CSSProperties;
  button_medium: CSSProperties;
  button_large: CSSProperties;
  alert_description: CSSProperties;
  alert_title: CSSProperties;
  role_card_name: CSSProperties;
  role_card_info: CSSProperties;
  allcaps: CSSProperties;
}

export const MainTheme = createTheme({
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    primary: {
      light: "rgba(150, 54, 150, 1)",
      main: "rgba(105, 32, 111, 1)",
      dark: "rgba(69, 5, 74, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
      50: "#FAF4FC",
      100: "#ECDBE7",
      200: "#ca92c1",
      300: "#b468a8",
      400: "#a34a96",
      500: "#933086",
      600: "#872b80",
      700: "#772578",
      800: "#68206f",
      900: "#4e195e",
      A100: "#872b80",
      A200: "#772578",
      A400: "#68206f",
      A700: "#4e195e",
    },
    secondary: {
      light: "rgba(97, 56, 105, 1)",
      main: "rgba(71, 41, 76, 1)",
      dark: "rgba(55, 32, 59, 1)",
      50: "#f0e6ee",
      100: "#dbc0d5",
      200: "#c598bb",
      300: "#ac729f",
      400: "#9a588c",
      500: "#88447a",
      600: "#7d3e74",
      700: "#6f376b",
      800: "#603260",
      900: "#47294c",
      A100: "#7d3e74",
      A200: "#6f376b",
      A400: "#603260",
      A700: "#47294c",
    },
    action: {
      active: "rgba(0, 0, 0, 0.56)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      focus: "rgba(0, 0, 0, 0.12)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    error: {
      dark: "rgba(227, 7, 7, 1)",
      main: "rgba(249, 50, 50, 1)",
      light: "#F94B4B",
      300: "rgba(249, 75, 75, 0.5)",
    },
    warning: {
      light: "#ED6C0280",
      300: "rgba(242, 143, 50, 0.5)",
      main: "rgba(240, 129, 26, 1)",
      dark: "rgba(222, 114, 14, 1)",
    },
    info: {
      main: "rgba(2, 136, 209, 1)",
      dark: "rgba(1, 87, 155, 1)",
      light: "rgba(3, 169, 244, 0.7)",
      300: "rgba(3, 169, 244, 0.5)",
    },
    success: {
      light: "rgba(5, 192, 36, 1)",
      main: "rgba(4, 153, 28, 1)",
      300: "rgba(5, 192, 36, 0.5)",
      dark: "rgba(3, 120, 23, 1)",
    },
    background: {
      default: "rgba(255, 255, 255, 1)",
      paper: "rgba(255, 255, 255, 1)",
    },
    grey: {
      50: "rgba(250, 250, 250, 1)",
      100: "rgba(245, 245, 245, 1)",
      200: "rgba(238, 238, 238, 1)",
      300: "rgba(224, 224, 224, 1)",
      400: "rgba(189, 189, 189, 1)",
      500: "rgba(158, 158, 158, 1)",
      600: "rgba(117, 117, 117, 1)",
      700: "rgba(97, 97, 97, 1)",
      800: "rgba(66, 66, 66, 1)",
      900: "rgba(33, 33, 33, 1)",
      A100: "rgba(213, 213, 213, 1)",
      A200: "rgba(170, 170, 170, 1)",
      A400: "rgba(97, 97, 97, 1)",
      A700: "rgba(48, 48, 48, 1)",
    },
  },
  typography: {
    link_large: {
      fontSize: 14,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    link_medium: {
      fontSize: "0.75rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    link_small: {
      fontSize: "0.625rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    small_text1: {
      fontSize: "0.625rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    small_text2: {
      fontSize: 10,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    small_text3: {
      fontSize: "0.5rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "12px",
    },
    masthead_menu: {
      fontSize: 10,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    card_label1: {
      fontSize: "0.5rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0px",
      lineHeight: "14px",
    },
    card_label2: {
      fontSize: "0.5rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    card_label3: {
      fontSize: "0.5rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    h1: {
      fontSize: "2.125rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.25px",
      lineHeight: "123.50000143051147%",
    },
    h2: {
      fontSize: "1.5rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0px",
      lineHeight: "133.39999914169312%",
    },
    h3: {
      fontSize: "1.25rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "160.0000023841858%",
    },
    h4: {
      fontSize: "1rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    h5: {
      fontSize: 16,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "116.68164730072021%",
    },
    h6: {
      fontSize: "0.9375rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0%",
      lineHeight: "120.99999189376831%",
    },
    p1: {
      fontSize: 16,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "150%",
    },
    p2: {
      fontSize: 16,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "175%",
    },
    p3: {
      fontSize: 14,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.17000000178813934px",
      lineHeight: "142.99999475479126%",
    },
    p4: {
      fontSize: 14,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.10000000149011612px",
      lineHeight: "157.00000524520874%",
    },
    p5: {
      fontSize: 12,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    form_h1: {
      fontSize: "0.875rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "165.9999966621399%",
    },
    form_text1: {
      fontSize: "0.875rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    form_text2: {
      fontSize: "0.75rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    form_text3: {
      fontSize: "0.625rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    naviagtion_selected: {
      fontSize: 12,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "165.9999966621399%",
    },
    navigation_regular: {
      fontSize: 12,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "165.9999966621399%",
    },
    date_picker_current_month: {
      fontSize: 16,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "150%",
    },
    bottom_navigation_active_label: {
      fontSize: 14,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "165.9999966621399%",
    },
    list_subheader: {
      fontSize: 14,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.10000000149011612px",
      lineHeight: "48px",
    },
    table_header: {
      fontSize: 14,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.17000000178813934px",
      lineHeight: "24px",
    },
    tooltip_label: {
      fontSize: 10,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0px",
      lineHeight: "14px",
    },
    caption: {
      fontSize: "0.625rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0px",
      lineHeight: "14px",
    },
    input_text: {
      fontSize: 16,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "24px",
    },
    helper_text: {
      fontSize: "0.75rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "165.9999966621399%",
    },
    helper_text_small: {
      fontFamily: "Open Sans",
      fontSize: "0.625rem",
      fontWeight: "600",
      lineHeight: "14px",
      letterSpacing: "0px",
      textAlign: "left",
    },
    input_label: {
      fontSize: 12,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "12px",
    },
    field_text1: {
      fontSize: "0.75rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.14000000059604645px",
      lineHeight: "20px",
    },
    avatar_initials: {
      fontSize: 20,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.14000000059604645px",
      lineHeight: "20px",
    },
    chip_label: {
      fontSize: "0.8125rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.1599999964237213px",
      lineHeight: "18px",
    },
    xs_label: {
      fontSize: "0.5rem",
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    menu_item_dense: {
      fontSize: 14,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.17000000178813934px",
      lineHeight: "24px",
    },
    menu_item: {
      fontSize: 16,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "150%",
    },
    button_small: {
      fontSize: 13,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0.46000000834465027px",
      lineHeight: "22px",
    },
    button_medium: {
      fontSize: 14,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "24px",
    },
    button_large: {
      fontSize: "0.9375rem",
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0.46000000834465027px",
      lineHeight: "26px",
    },
    alert_description: {
      fontSize: 14,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "142.99999475479126%",
    },
    alert_title: {
      fontSize: 16,
      fontFamily: "Open Sans, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "150%",
    },
    role_card_name: {
      fontSize: 8,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    role_card_info: {
      fontSize: 8,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    allcaps: {
      fontSize: 14,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "24px",
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          padding: "15px",
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            display: "none",
          },
        },
      },
      styleOverrides: {
        scroller: {
          display: "inline-flex",
        },
        flexContainer: {
          display: "inline-flex",
        },
        root: {
          display: "inline-flex",
          border: "1px solid #EEEFF0",
          padding: "3px",
          borderRadius: "12px",
          minHeight: "0",
          height: "37px",
          "& button": {
            fontFamily: "Public Sans, sans-serif",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "21px",
            letterSpacing: "0em",
            textAlign: "left",
            textTransform: "capitalize",
            padding: "10px 5px",
            minHeight: "0",
          },
          "& button.Mui-selected": {
            backgroundColor: "#FAF4FC",
            borderRadius: "9px",
            boxShadow: "2px 2px 5px 0px #B1AEBB",
            margin: "5px 2.5px",
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          "& button": {
            textTransform: "capitalize",
            color: "black",
            padding: "0px 6px",
          },
          "& button.MuiButtonGroup-grouped:not(:last-of-type)": {
            borderRight: "1px solid #bdbdbd",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "rgba(150, 54, 150, 1)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontFamily: "Open Sans, sans-serif",
          paragraphIndent: 0,
          paragraphSpacing: 0,
          fontWeight: 400,
          letterSpacing: "0.4000000059604645px",
          lineHeight: "165.9999966621399%",
        },
      },
    },
  },
});
