import { createStore, Store } from "redux";
import rootReducer from "./Reducers/index";

const rootElement = document.getElementById("root") || {
  dataset: { debug: "false" },
};
const debug = rootElement.dataset.debug;

let store: Store;
const win = window as any;

if (debug === "true") {
  store = createStore(
    rootReducer,
    win.__REDUX_DEVTOOLS_EXTENSION__ && win.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(rootReducer);
}

export default store;
