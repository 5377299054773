import React from "react";
import Button_1 from "./Button_1";
import { ButtonPropTypes } from "./types";

const reactMemo: <T>(
  c: T,
  propsAreEqual: (prev: any, curr: any) => boolean
) => T = React.memo;

export default reactMemo(Button_1, (prevProps, currProps): boolean => {
  const fields = Object.keys(prevProps).filter(
    (f) => !["startIcon"].includes(f)
  ) as (keyof ButtonPropTypes)[];
  for (let i = 0; i < fields.length; i++) {
    if (prevProps[fields[i]] !== currProps[fields[i]]) {
      console.log("Rerendered based on: " + fields[i] + " " + currProps.label);
      return false; // meaning, the component should rerender;
    }
  }
  return true;
});
