import React, { lazy, Suspense, StrictMode } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import AuthenticationWrapper from "./Components/Modules/Authentication";
import { Box } from "@mui/material";

const UserLayout = lazy(() => import("./Components/Layout/User"));
const SettingsLayout = lazy(() => import("./Components/Layout/Settings"));

function App() {
  console.log(process.env.REACT_APP_SERVER_NAME, "SERVER_NAME");
  return (
    <Box className="App" style={{ position: "relative" }}>
      <StrictMode>
        <Suspense fallback={<div></div>}>
          <AuthenticationWrapper>
            <Router>
              <>
                <Suspense fallback={<div></div>}>
                  <Switch>
                    <Route path="/settings">
                      <SettingsLayout />
                    </Route>
                    <Route path="/">
                      <UserLayout />
                    </Route>
                  </Switch>
                </Suspense>
              </>
            </Router>
          </AuthenticationWrapper>
        </Suspense>
      </StrictMode>
    </Box>
  );
}

export default App;
