import { combineReducers } from "redux";
import DataReducer from "./DataReducer";
import AuthReducer from "./AuthReducer";
import SideNavReducer from "./SideNavReducer";

export default combineReducers({
  DataReducer,
  AuthReducer,
  SideNavReducer,
});
